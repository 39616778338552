<template>
     <div class="apply_coorperation">
          <el-dialog width="686px" lock-scroll="false" v-model="dialogVisible" top="7vh">
               <div>
                    <div class="bd6 flex_row_between_center">
                         <span class="info15">申请合作</span>
                         <img class="icon3" src="@/assets/close.png" @click="dialogVisible = false" />
                    </div>

                    <div class="table_cell">
                         <table style="border-spacing:20px;">
                              <tr>
                                   <td class="td_title"><span><i class="ico">*</i>联系人：</span></td>
                                   <td>
                                        <el-input placeholder="请输入联系人" v-model="contactName"
                                             @input="handleInput('contactName')" show-word-limit maxlength="25">
                                        </el-input>
                                   </td>
                                   <td>
                                        <span class="errMsg">{{ errMsg.contactName }}</span>
                                   </td>
                              </tr>
                              <tr>
                                   <td class="td_title"><span><i class="ico">*</i>联系人电话：</span></td>
                                   <td>
                                        <el-input type="number" placeholder="请输入联系人电话" v-model="contactPhone"
                                             @input="handleInput('contactPhone')">
                                        </el-input>
                                   </td>
                                   <td>
                                        <span class="errMsg">{{ errMsg.contactPhone }}</span>
                                   </td>
                              </tr>
                              <tr>
                                   <td class="td_title" valign="top"><i class="ico">*</i><span>上传申请表：</span></td>
                                   <td>
                                        <div v-if="saleCooperateApplyUrlA">
                                             <div class="d_model" @click="down_model">下载模板
                                             </div>
                                             <div class="outer2 flex-col" id="dropZone" :v-loading="uploading"
                                                  v-show="loadState == 'do'">
                                                  <div class="mod2 flex_column_center_center">
                                                       <img class="img1" referrerpolicy="no-referrer"
                                                            src="../../../assets/member/sketch_file.png" />
                                                       <div class="layer1">
                                                            <span class="word2">将文件拖入框内，或</span>
                                                            <label for="upload">
                                                                 <span class="word3">点击上传</span>
                                                            </label>
                                                            <input type="file" name="" id="upload" size="1"
                                                                 accept="image/jpg,application/pdf,image/jpeg"
                                                                 @change="getFile" ref="inputTab">
                                                       </div>
                                                       <div class="layer22">
                                                            <span>
                                                                 支持上传.pdf文件和JPG格式的图片
                                                            </span>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div class="layer_success" v-show="loadState == 'done'">
                                                  <div class="success_excel flex_row_between_center"
                                                       v-if="demFile.type == 'pdf'">
                                                       <div class="left flex_row_start_center">
                                                            <img src="../../../assets/prototype/PDF.png" alt="">
                                                            <span>申请表.pdf</span>
                                                       </div>
                                                       <div class="right">
                                                            <div class="right_con" @click="clearFile">
                                                                 <span class="el-icon-delete"></span>
                                                            </div>
                                                       </div>
                                                  </div>
                                                  <div class="success_img" v-else>
                                                       <div class="img_con"
                                                            :style="{ backgroundImage: 'url(' + demFile.url + ')' }">
                                                       </div>
                                                       <div class="del_con" @click="clearFile">
                                                            <span class="el-icon-delete"></span>
                                                       </div>
                                                  </div>
                                             </div>

                                        </div>

                                        <div v-else class="no_template_text">
                                             该供应商没有设置申请模板，请稍候再试～
                                        </div>
                                   </td>
                                   <td>
                                        <span class="errMsg" valign="top">{{ errMsg.file }}</span>
                                   </td>
                              </tr>

                         </table>
                    </div>

                    <div class="main_down flex_row_end_center">
                         <div class="but1" @click="dialogVisible = false">取消</div>
                         <div class="but2" @click="confirmApi" v-if="saleCooperateApplyUrlA">确定</div>
                    </div>
               </div>
          </el-dialog>
     </div>
</template>

<script>
import { getCurrentInstance, reactive, ref, onMounted, watch } from "vue";
import { ElMessage } from 'element-plus'
import { useStore } from 'vuex'
export default {
     setup(props, { emit }) {
          const store = useStore()
          const dialogVisible = ref(false)
          const demFile = ref({})
          const contactName = ref('')
          const contactPhone = ref('')
          const uploading = ref(false)
          const loadState = ref('do')
          const { proxy } = getCurrentInstance()
          const errMsg = reactive({ contactName: '', contactPhone: '', file: '' })
          const storeName = ref('')
          const storeId = ref(null)
          const memberInfo = store.state.memberInfo
          // 上传文件
          const uploadFile = (targetFile) => {
               console.log(targetFile, 'taget')
               let typelist = ['image/jpg', 'image/jpeg', 'application/pdf']

               let file = targetFile
               let index = typelist.findIndex(i => file.type == i)
               if (index < 0) {
                    ElMessage('上传的文件格式不正确')
                    return
               }
               if (file) {
                    uploading.value = true
                    proxy.$post('v3/oss/common/upload', {
                         source: 'apply',
                         file
                    }, 'form').then(res => {
                         if (res.state == 200) {
                              loadState.value = 'done'
                              uploading.value = false
                              proxy.$refs.inputTab.value = ''
                              demFile.value = {
                                   url: res.data.url,
                                   path: res.data.path,
                                   type: res.data.path.substring(res.data.path.lastIndexOf('.') + 1)
                              }
                              errMsg.file = ''
                         } else {
                              ElMessage(res.msg)
                              uploading.value = false
                         }
                    })
               }

          }

          const handleInput = (type) => {
               errMsg[type] = ''

               if (type == 'contactPhone') {
                    contactPhone.value = contactPhone.value.slice(0, 11)
               }
          }


          const getFile = (e) => {
               uploadFile(e.target.files[0])
          }

          const clearFile = () => {
               demFile.url = ''
               demFile.path = ''
               demFile.type = '';
               loadState.value = 'do'
          }

          const fileDrag = () => {
               var dz = document.getElementById('dropZone');
               dz.ondragover = function (e) {
                    //阻止浏览器默认打开文件的操作  
                    e.preventDefault();
                    e.stopPropagation();
                    //拖入文件后边框颜色变红  
                    e.dataTransfer.dropEffect = 'copy';
               }
               dz.ondragleave = function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    //恢复边框颜色  
                    this.style.borderColor = 'gray';
               }
               dz.ondrop = function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    //恢复边框颜色  
                    this.style.borderColor = '#d9d9d9';
                    //阻止浏览器默认打开文件的操作  
                    e.preventDefault();
                    let filesTarget = e.dataTransfer.files[0];
                    uploadFile(filesTarget)
               }
          }


          const down_model = () => {
               proxy.$get('v3/seller/front/storeCooperationApply/getTemplate', { supplierId: storeId.value }, 'blob').then(res => {
                    // let tmp = new Blob([res], { type: 'application/vnd.ms-excel' })

                    let tmp = res.data
                    // const aLink = document.createElement('a');
                    // document.body.appendChild(aLink);
                    // aLink.style.display = 'none';
                    // aLink.href = tmp
                    // aLink.setAttribute('download', '标准模板');
                    // aLink.click();
                    // document.body.removeChild(aLink);
                    window.open(tmp, '_blank')
               })
          }

          const checkInput = () => {
               if (!contactName.value) {
                    errMsg.contactName = '请输入联系人'
                    return false
               }

               if (proxy.$checkPhone(contactPhone.value, '请输入联系人电话', '请正确输入联系人电话') != true) {
                    errMsg.contactPhone = proxy.$checkPhone(contactPhone.value, '请正确输入联系人电话')
                    return false
               }

               if (!demFile.value.url) {
                    errMsg.file = '请上传申请表'
                    return false
               }

               return true
          }

          const confirmApi = () => {
               if (!checkInput()) {
                    return
               }

               let param = {
                    applyFrom: demFile.value.url,
                    contactName: contactName.value,
                    contactPhone: contactPhone.value,
                    storeName: memberInfo.memberName,
                    supplierId: storeId.value
               }


               proxy.$post('v3/seller/front/storeCooperationApply/apply', param).then(res => {
                    if (res.state == 200) {
                         ElMessage.success(res.msg)
                         emit('update', storeId.value)
                         dialogVisible.value = false
                    } else {
                         ElMessage.error(res.msg)
                    }
               })
          }
          const listIndex = ref(-1)
          const saleCooperateApplyUrlA = ref(true)
          const setStoreInfo = (storeNameA, storeIdA, saleCooperateApplyUrl) => {
               console.log(storeNameA, storeIdA)
               storeId.value = storeIdA
               storeName.value = storeNameA
               saleCooperateApplyUrlA.value = saleCooperateApplyUrl ? true : false
          }


          watch(dialogVisible, () => {
               if (dialogVisible.value) {
                    setTimeout(() => {
                         fileDrag()
                    }, 1000)
               }
          })

          onMounted(() => {
               // setTimeout(() => {

               // }, 500)
               dialogVisible.value = false

          })

          return {
               getFile,
               dialogVisible,
               demFile,
               contactName,
               contactPhone,
               clearFile,
               loadState,
               handleInput,
               errMsg,
               confirmApi,
               down_model,
               setStoreInfo,
               saleCooperateApplyUrlA
          }
     }
}
</script>


<style lang="scss">
/*添加css样式*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
     -webkit-appearance: none;
}

input[type="number"] {
     -moz-appearance: textfield;
}

.el-dialog__body {
     padding: 0 !important;
}

.no_template_text {
     color: $colorMain;
     font-size: 14px;
     width: 450px;
}


.apply_coorperation {
     .el-dialog .el-dialog__header {
          display: none;
     }



     .el-input__inner:focus {
          outline: 0;
          border-color: $colorMain;
     }

     .td_title {
          text-align: right;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #666666;
          white-space: nowrap;
     }

     .errMsg {
          font-size: 12px;
          margin-top: 5px;
          color: #E2231A;
     }

     .table_cell {
          padding-left: 20px;
     }

     .ico {
          color: #e2231a;
          font-style: normal;
     }

     .d_model {
          width: 78px;
          height: 28px;
          background: #E2231A;
          border-radius: 6px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 28px;
          text-align: center;
          cursor: pointer;
          margin-bottom: 10px;
     }

     .outer2 {
          z-index: 9;
          height: 192px;
          border: 1px solid rgba(217, 217, 217, 1);
          background-color: rgba(255, 255, 255, 1);
          width: 384px;
          justify-content: center;
          align-items: center;
          display: flex;

          .mod2 {
               z-index: auto;

               .img1 {
                    z-index: 10;
                    width: 50px;
                    height: 50px;
                    align-self: center;
               }

               .layer1 {
                    z-index: 16;
                    width: 182px;
                    height: 22px;
                    overflow-wrap: break-word;
                    text-align: left;
                    white-space: nowrap;
                    margin-top: 30px;
                    font-size: 0;

                    .word2 {
                         font-size: 14px;
                         font-family: PingFangSC-Regular;
                         color: rgba(153, 153, 153, 1);
                         line-height: 22px;
                         overflow: hidden;
                         text-overflow: ellipsis;
                    }

                    .word3 {
                         font-size: 14px;
                         font-family: PingFangSC-Regular;
                         color: rgba(40, 95, 222, 1);
                         line-height: 22px;
                         overflow: hidden;
                         text-overflow: ellipsis;
                         cursor: pointer;
                    }
               }

               #upload {
                    display: none;
               }

               .layer22 {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    margin-top: 10px;
               }
          }
     }

     .layer_success {
          .success_excel {
               height: 62px;
               border-radius: 2px;
               border: 1px solid rgba(217, 217, 217, 1);
               background-color: rgba(255, 255, 255, 1);
               width: 260px;
               padding-left: 10px;

               .left {
                    img {
                         width: 42px;
                         height: 42px;
                    }

                    span {
                         margin-left: 15px;
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                         font-weight: 400;
                         color: #333333;
                    }
               }

               .right {
                    display: flex;
                    align-self: flex-start;
                    padding-top: 5px;
                    padding-right: 5px;

                    .right_con {
                         cursor: pointer;
                         width: 18px;
                         height: 18px;
                         background: #999;
                         border-radius: 50%;
                         text-align: center;
                         line-height: 18px;
                         align-self: flex-start;

                         .el-icon-delete {
                              color: #fff;
                         }
                    }

               }



          }

          .success_img {
               position: relative;
               width: 181px;
               height: 181px;
               background: #FFFFFF;
               border-radius: 2px;
               border: 1px solid #D9D9D9;

               .img_con {
                    width: 179px;
                    height: 179px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: contain;
               }

               .del_con {
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    cursor: pointer;
               }
          }
     }

     .bd6 {
          background-color: rgba(226, 35, 26, 1);
          height: 40px;
          padding: 0 20px;

          .info15 {
               color: rgba(255, 255, 255, 1);
               font-size: 16px;
          }

          .icon3 {
               width: 16px;
               height: 16px;
               cursor: pointer;
          }
     }

     .main_down {
          border-top: 1px solid #F2F2F2;
          height: 67px;
          padding: 0 20px;
          margin-top: 30px;

          .but1 {
               width: 78px;
               height: 36px;
               background: #EEEEEE;
               border-radius: 6px;
               font-size: 16px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               color: #666262;
               line-height: 36px;
               text-align: center;
               cursor: pointer;
          }

          .but2 {
               margin-left: 20px;
               width: 78px;
               height: 36px;
               background: #E2231A;
               border-radius: 6px;
               font-size: 16px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               color: #FFFFFF;
               line-height: 36px;
               text-align: center;
               cursor: pointer;
          }
     }
}
</style>