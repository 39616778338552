//购物车页面
<template>
	<div class="sld_cart_index">
		<div class="cart_content"
			v-if="cartData.data &&(cartData.data.storeCartGroupList &&cartData.data.storeCartGroupList.length > 0)">
			<!-- 购物车列表顶部标题 start-->
			<div class="cart_title flex_row_start_center">
				<div class="cart_title_pre flex_row_start_center"
					v-if="cartData.data.storeCartGroupList.length > 0">
					<img src="@/assets/cart/sel.png" alt="" v-if="cartData.data.checkedAll"
						@click="changeSelectState('all', cartData.data.checkedAll)" />
					<img src="@/assets/cart/no_sel.png" alt="" v-if="!cartData.data.checkedAll"
						@click="changeSelectState('all', cartData.data.checkedAll)" />
					<span>{{ L["全选"] }}</span>
				</div>
				<div class="cart_title_pre" style="width:62px;" v-else></div>
				<div class="cart_title_pre">
					<span>商品信息</span>
				</div>
				<div class="cart_title_pre">
				</div>
				<div class="cart_title_pre">
					<span>{{ L["数量"] }}</span>
				</div>
				<div class="cart_title_pre">
					<span>供货价</span>
				</div>
				<div class="cart_title_pre">
					<span>{{ L["小计（元）"] }}</span>
				</div>
				<div class="cart_title_pre">
					<span>{{ L["操作"] }}</span>
				</div>
			</div>
			<!-- 购物车列表顶部标题 end-->
			<!-- 购物车列表 start -->
			<div class="cart_lists" v-show="cartData.data">
				<div class="cart_lists_pre" v-for="(cartGroupItem, cartGroupIndex) in
                  firstLoading ? skelelonData : cartData.data.storeCartGroupList" :key="cartGroupIndex"
					:class="{ skelelon_loading: firstLoading }">
					<!-- 店铺信息 start -->
					<div class="store_info flex_row_start_center">
						<!-- 可选择 -->
						<img src="@/assets/cart/sel.png" alt="" class="store_sel"
							v-if="cartGroupItem.checkedAll && !cartGroupItem.lackAll"
							@click="changeSelectState('store', cartGroupItem.checkedAll, cartGroupItem)" />
						<img src="@/assets/cart/no_sel.png" alt="" class="store_sel"
							v-if="!cartGroupItem.checkedAll && !cartGroupItem.lackAll"
							@click="changeSelectState('store', cartGroupItem.checkedAll, cartGroupItem)" />
						<!-- 全部无货，不可选择 -->
						<img src="@/assets/cart/no_sel.png" alt="" class="store_sel"
							v-if="cartGroupItem.lackAll" />
						<router-link target="_blank" :to="`/store/index?vid=${cartGroupItem.storeId}`"
							class="store_des flex_row_start_center cursor_pointer">
							<span>{{ cartGroupItem.storeName }}</span>
							<img src="@/assets/cart/right_down.png" alt="" />
						</router-link>

						<!-- <img class="store_chat_img" @click="service(cartGroupItem.storeId)" :src="storeImg" /> -->
					</div>
					<!-- 店铺信息 end -->
					<!-- 商品信息 start -->
					<div class="goods_con">
						<!-- 商品列表 start -->
						<div class="goods_list">
							<div class="goods_pre"
								:class="{ flex_row_start_start:4>1,flex_row_start_center:4==1}"
								v-for="(cartItem, cartIndex) in cartGroupItem.storeGoodsList" :key="cartIndex">
								<!-- v-for="(cartItem, cartIndex) in promotionCartGroupItem.cartList" :key="cartIndex"> -->
								<!-- 可选择 1-正常状态，2-商品失效（已删除），3-商品无货-->
								<div class="flex_row_start_center">
									<div class="goods_pre_sel">
										<img src="@/assets/cart/sel.png" alt="" class="store_sel"
											v-if="cartItem.checkedAll"
											@click="changeSelectState('goods', cartItem.checkedAll, cartItem)" />

										<img src="@/assets/cart/sel_part.png" alt="" class="store_sel"
											v-if="!cartItem.checkedAll&&cartItem.goodsCartList.length>cartItem.totalCheck&&cartItem.totalCheck>0"
											@click="changeSelectState('goods', cartItem.checkedAll, cartItem)" />

										<img src="@/assets/cart/no_sel.png" alt="" class="store_sel"
											v-if="!cartItem.checkedAll&&cartItem.totalCheck==0"
											@click="changeSelectState('goods', cartItem.checkedAll, cartItem)" />

									</div>
									<!-- 商品信息 -->
									<div :style="{ backgroundImage: 'url(' + cartItem.mainImage + ')' }"
										class="goods_img"
										:class="{ no_stock_img: cartItem.productState == 2 }"
										@click="goGoodsDetail(cartItem.goodsCartList[0].productId)"
										style="cursor: pointer;"></div>
									<div class="goods_des">
										<div class="goods_name_box">
											<p class="goods_name"
												:class="{ no_stock_name: cartItem.productState == 2 }"
												@click="goGoodsDetail(cartItem.goodsCartList[0].productId)"
												style="cursor: pointer;">
												{{ cartItem.goodsName }}
											</p>
										</div>

									</div>
								</div>
								<div class="sku_list">
									<div class="flex_row_start_center sku_item"
										v-for="(item,index) in cartItem.goodsCartList" :key="index">
										<!-- 单个sku规格 -->
										<div class="goods_sku flex_row_start_start"
											:class="{invalid_info:item.productState == 2}">
											<div class="goods_pre_sel">
												<div v-if="item.productState == 1&&item.specValueIds">
													<img src="@/assets/cart/sel.png" alt=""
														class="store_sel" v-if="item.isChecked"
														@click="changeSelectState('spec', item.isChecked, item)" />
													<img src="@/assets/cart/no_sel.png" alt=""
														class="store_sel" v-if="!item.isChecked"
														@click="changeSelectState('spec', item.isChecked, item)" />
												</div>
												<!-- 不可选择 无货-->
												<div class="invalid_sku" v-if="item.productState == 2">失效
												</div>
											</div>
											<div class="sku_spec">
												<div class="sku_value"
													@click="goGoodsDetail(item.productId)">
													{{item.specValues||'默认'}}</div>
											</div>
										</div>
										<!-- 数量 -->
										<div class="goods_num flex_column_center_start"
											:class="{invalid_info:item.productState==2}">
											<div class="flex_row_start_center">
												<div class="goods_edit_nem flex_row_center_center">
													<span @click="editNum('reduce', item)">-</span>
													<input type="number" v-model="item.buyNum"
														@input="editNum('edit', item, $event)"
														@blur="editNum('blur', item, $event)" />
													<span @click="editNum('add', item)">+</span>
												</div>

											</div>
											<div class="goods_stocks goods_stocks_no"
												v-if="item.productState == 3">
												{{ L["库存不足"] }}
											</div>
											<div class="goods_stocks goods_stocks_red"
												v-if="item.buyNum > item.productStock">
												{{ L["剩余"] }} {{ item.productStock }} {{ L["件"] }}
											</div>
										</div>
										<!-- 单价 -->
										<div class="goods_price_prom"
											:class="{invalid_info:item.productState==2}">
											<div class="goods_price"
												:class="{ no_stock_price: item.productState == 2 }">
												￥{{ item.goodsPrice ? item.goodsPrice.toFixed(2) : 0 }}
											</div>
										</div>
										<!-- 小计 -->
										<div class="subtotal"
											:class="{ invalid_info: item.productState == 2 }">
											￥{{ item.totalAmount}}
										</div>
										<!-- 操作 -->
										<div class="goods_btn flex_row_center_start">
											<el-popconfirm title="确定删除选中商品？"
												@confirm="delCartGoods('goods', item)"
												:confirmButtonText="L['确定']" :cancelButtonText="L['取消']">
												<template #reference>
													<span class="goods_del">{{ L["删除"] }}</span>
												</template>
											</el-popconfirm>
										</div>
									</div>
								</div>

							</div>
						</div>
						<!-- 商品列表 end -->
					</div>
					<!-- 商品信息 end -->
				</div>
			</div>
			<!-- 购物车列表 end -->
			<!-- 操作按钮 start -->
			<div class="options_btn flex_row_between_center" ref="optionsBtn">
				<div class="cart_bottom_btn flex_row_start_center">
					<div class="options_sel"
						v-if="cartData.data.storeCartGroupList&&cartData.data.storeCartGroupList.length > 0">
						<img src="@/assets/cart/sel.png" alt="" class="store_sel" v-if="cartData.data.checkedAll"
							@click="changeSelectState('all', cartData.data.checkedAll)" />
						<img src="@/assets/cart/no_sel.png" alt="" class="store_sel"
							v-if="!cartData.data.checkedAll"
							@click="changeSelectState('all', cartData.data.checkedAll)" />
						<span>{{ L["全选"] }}</span>
					</div>
					<div class="flex_col_start_center">
						<div class="del_all cursor_pointer" @click="delBatchGoods"
							v-if="cartData.data.storeCartGroupList&&cartData.data.storeCartGroupList.length > 0">
							删除选中的商品
						</div>
						<div class="del_all_invalid cursor_pointer" @click="clearInvalidGoods">
							清空失效的商品
						</div>
					</div>
					<div class="options_btn_left flex_row_start_center">
						<div class="options_btn_left_check">已选<span>{{ totalCheck }}</span>件商品</div>
						<div class="options_btn_left_tip flex_row_start_center">
							<span class="options_btn_left_tit">，小计：</span>
							<span class="options_btn_left_red">￥{{cartData.data.totalAmount}}</span>
						</div>
					</div>
				</div>
				<div class="options_right flex_row_start_center">
					<div class="options_btn_left_sum flex_row_start_center">
						<span class="options_btn_left_tit">{{ L["合计"] }}：</span>
						<span class="options_btn_left_red">￥{{cartData.data.totalAmount}}</span>
						<span class="options_btn_left_red"
							v-if="cartData.data.totalDiscount">￥{{cartData.data.totalDiscount}}</span>
					</div>
					<div class="go_buy cursor_pointer" @click="goBuy"
						:class="{ active: cartData.data.totalAmount > 0, go_submit: cartData.data.totalAmount ==0 }">
						{{ L["去结算"] }}
					</div>
				</div>
			</div>
			<!-- 操作按钮 end -->
		</div>
		<div class="cart_content_no flex_column_center_center"
			v-show="!cartData.data ||JSON.stringify(cartData.data) == '{}' ||(cartData.data &&cartData.data.storeCartGroupList &&cartData.data.storeCartGroupList.length == 0)">
			<img src="@/assets/common_empty.png" alt="" />
			<p>这里空空如也，快去首页逛逛吧～</p>
			<span @click="goIndex">去首页</span>
		</div>
		<!-- 清空失效商品 弹框 start -->
		<el-dialog title="提示" v-model="clearInvalidGoodsModel" width="20%" center top="30vh">
			<div style="margin:0 auto; text-align: center">确定清空失效商品？</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="cancelClearInvalidModel">{{ L["取 消"] }}</el-button>
					<el-button type="primary" @click="confirmClearInvalidModel">{{ L["确 定"] }}</el-button>
				</span>
			</template>
		</el-dialog>
		<!-- 清空失效商品 弹框 end -->

		<!-- 删除所选商品 弹框 start -->
		<el-dialog title="提示" v-model="chosenGoodsModel" width="20%" center top="30vh">
			<div style="margin:0 auto; text-align: center">确定删除选中商品？</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="chosenGoodsModel = false">{{ L["取 消"] }}</el-button>
					<el-button type="primary" @click="delCartGoods('batch')">{{ L["确 定"] }}</el-button>
				</span>
			</template>
		</el-dialog>
		<!-- 删除所选商品 弹框 end -->



		<!-- 推荐商品 start -->
		<recommend-list :firstLoading="firstLoading"></recommend-list>
		<!-- 推荐商品 end -->

		<!-- 登录弹框 start -->
		<SldLoginModal :visibleFlag="loginModalVisibleFlag" @closeLoingModal="closeLoingModal" />
		<!-- 登录弹框 end -->

		<!-- 底部操作悬浮框 start -->
		<div class="bottom_options" v-if="optionsBtnShow">
			<div class="bottom_options_con flex_row_between_center">
				<div class="cart_bottom_btn flex_row_start_center">
					<div class="options_sel"
						v-if="cartData.data.storeCartGroupList&&cartData.data.storeCartGroupList.length > 0">
						<img src="@/assets/cart/sel.png" alt="" class="store_sel" v-if="cartData.data.checkedAll"
							@click="changeSelectState('all', cartData.data.checkedAll)" />
						<img src="@/assets/cart/no_sel.png" alt="" class="store_sel"
							v-if="!cartData.data.checkedAll"
							@click="changeSelectState('all', cartData.data.checkedAll)" />
						<span>{{ L["全选"] }}</span>
					</div>
					<div class="flex_col_start_center">
						<div class="del_all cursor_pointer" @click="delBatchGoods"
							v-if="cartData.data.storeCartGroupList&&cartData.data.storeCartGroupList.length > 0">
							删除选中的商品
						</div>
						<div class="del_all_invalid cursor_pointer" @click="clearInvalidGoods">
							清空失效的商品
						</div>
					</div>
				</div>
				<div class="options_right flex_row_start_center">
					<div class="options_btn_left_sum flex_row_start_center">
						<span class="options_btn_left_tit">{{ L["合计"] }}：</span>
						<span class="options_btn_left_red">￥{{cartData.data.totalAmount}}</span>
						<span class="options_btn_left_red"
							v-if="cartData.data.totalDiscount">￥{{cartData.data.totalDiscount}}</span>
					</div>
					<div class="go_buy cursor_pointer" @click="goBuy"
						:class="{ active: cartData.data.totalAmount > 0, go_submit: cartData.data.totalAmount == 0 }">
						{{ L["去结算"] }}
					</div>
				</div>
			</div>
		</div>
		<!-- 底部操作悬浮框 end -->
	</div>
</template>

<script>
	import { reactive, getCurrentInstance, ref, watchEffect, onMounted, computed } from "vue";
	import {
		ElMessage,
		ElDialog,
		ElButton,
		ElPopover,
		ElPopconfirm,
	} from "element-plus";
	import { useRouter } from "vue-router";
	import RecommendList from "../../components/RecommendList.vue";
	import CouponItem from "@/components/CouponItem";
	import { useStore } from "vuex";
	import SldLoginModal from "../../components/loginModal";
	export default {
		name: "CartIndex",
		components: {
			ElDialog,
			ElButton,
			ElPopover,
			ElPopconfirm,
			RecommendList,
			CouponItem,
			SldLoginModal,
		},
		setup() {
			const router = useRouter();
			const { proxy } = getCurrentInstance();
			const L = proxy.$getCurLanguage();
			const store = useStore();
			const token = ref(store.state.loginFlag); //登录缓存是否登录
			const loginModalVisibleFlag = ref(false); //登录弹框是否显示，默认不显示
			const cartData = reactive({ data: {} }); //购物车数据信息
			const clearInvalidGoodsModel = ref(false); //清空失效商品对话框是否显示
			const delGoodsCartIds = ref(""); //要删除的商品的购物车的id集
			const delType = ref("goods"); //要删除的商品类型：goods：单个商品删除 batch:批量删除
			const cartListData = reactive({ data: store.state.cartListData }); //获取vux的store中的购物车数据
			const optionsBtnTop = ref(0); //底部操作按钮元素的top值
			const windowHeight = ref(0); //屏幕元素的高度
			const optionsBtnShow = ref(false); //底部操作按钮悬浮框是否显示
			const optionsBtn = ref(null); //获取底部操作按钮元素
			const curStoreId = ref(-1); //当前打开的优惠券弹框的店铺id
			const elementScrollTop = ref(0); //页面滚动高度
			const chosenGoodsModel = ref(false)
			// const storeImg = require('../../assets/cart/storeChat.png');
			const firstLoading = ref(true);
			const skelelonData = ref([]);
			const memberInfo = ref(store.state.memberInfo); //获取vux的store中的购物车数据
			const isSubmit = ref(false); //是否在提交清单中


			const totalCheck = computed(() => {
				let checkNum = 0
				cartData.data.storeCartGroupList.forEach(item => {
					item.storeGoodsList.forEach(item2 => {
						if (item2.totalCheck > 0) checkNum++
					})
				});
				return checkNum
			})

			onMounted(() => {
				window.addEventListener('scroll', scrollHandle); //绑定页面滚动事件
				for (let i = 0; i <= 2; i++) {
					skelelonData.value.push({
						storeGoodsList: [{}]
					})
				}
			});
			//页面监听token是否更新
			watchEffect(() => {
				token.value = store.state.loginFlag;
				//实时获取屏幕的高度
				windowHeight.value = document.documentElement.clientHeight;

				if (optionsBtn.value != null && optionsBtn.value != undefined) {
					optionsBtnTop.value = optionsBtn.value.offsetTop;
					if (optionsBtnTop.value > windowHeight.value) {
						optionsBtnShow.value = true;
					} else {
						optionsBtnShow.value = false;
					}
					if (elementScrollTop.value + windowHeight.value > optionsBtnTop.value) {
						optionsBtnShow.value = false;
					} else {
						optionsBtnShow.value = true;
					}
				}
			});
			const scrollHandle = (e) => {
				elementScrollTop.value = e.srcElement.scrollingElement.scrollTop;
				// elementScrollTop.value = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
			};

			//获取购物车数据
			const getCartData = () => {
				//已登录
				let params = {};
				proxy
					.$get("v3/business/front/cart/cartList", params)
					.then((res) => {
						if (firstLoading.value) {
							firstLoading.value = false;
						}
						if (res.state == 200) {
							cartData.data = proxy.$calculateSubtotal(res.data);
						} else {
							ElMessage.error(res.msg);
						}
					})
			};
			getCartData();



			/**编辑购物车商品数量
			 * type:编辑类型 reduce：减  edit：编辑 add：加
			 * curCartItem：编辑的当前项商品
			 * e:事件对象
			 */
			const editNum = (type, curCartItem, e) => {
				let curNumber = curCartItem.buyNum;
				let minBuyNum = curCartItem.minBuyNum ? curCartItem.minBuyNum : 1;
				switch (type) {
					case 'reduce': {
						if (curNumber > 1) {
							curCartItem.buyNum--;
							curNumber--
						} else {
							curCartItem.buyNum = 1;
						}
						break
					}

					case 'add': {
						if (curNumber > curCartItem.productStock) {
							ElMessage(L["数量超出购买范围！"]);
							curCartItem.buyNum = curCartItem.productStock
							return
						} else if (curNumber < 1) {
							curCartItem.buyNum = 1;
						} else {
							curCartItem.buyNum++;
							curNumber++
							if (curNumber > 9999) {
								ElMessage(L["超过购买限制!"]);
								curCartItem.buyNum = 9999;
								curNumber = 9999
								return
							}
						}
						break;
					}


					case 'edit': {
						if (e.data == '.') {
							let num = curCartItem.buyNum.split('.')[0] ?
								curCartItem.buyNum.split('.')[0] : minBuyNum
							curCartItem.buyNum = '';
							setTimeout(() => {
								curCartItem.buyNum = num;
							})
							return;
						}
						curNumber = e.currentTarget.value;
						if (curNumber == 0 && curNumber != '') {
							curCartItem.buyNum = 1;
							curNumber = 1
							return
						} else if (curNumber == '') {
							return
						} else {
							curNumber = curNumber.replace(/\D/g, "");
							if (curNumber > curCartItem.productStock) {
								ElMessage(L["数量超出购买范围！"]);
								curCartItem.buyNum = curCartItem.productStock
								return
							}

							if (curNumber > 9999) {
								ElMessage(L["超过购买限制!"]);
								curCartItem.buyNum = 9999;
								return
							}
						}
						break
					}
					case 'blur': {
						if (curNumber == '') {
							curCartItem.buyNum = 1
						} else if (curNumber < 1) {
							curCartItem.buyNum = 1;
						} else if (curNumber > 9999) {
							ElMessage(L["超过购买限制!"]);
							curCartItem.buyNum = 9999;
						}
					}
				}
				curNumber = curCartItem.buyNum
				//已登录
				let params = {
					cartId: curCartItem.cartId, //商品购物车id
					number: curNumber, //修改后商品数量
				};

				proxy.$post("v3/business/front/cart/changeNum", params).then((res) => {
					if (res.state == 200) {
						cartData.data = proxy.$calculateSubtotal(res.data);
						store.commit("updateCartListData", cartData.data);
					} else {
						ElMessage.error(res.msg);
					}
				});
			};



			/**选中状态的更改
			 * type ： goods:商品， store :店铺 all,全部（全选或者全不选）
			 * isCheckedState：商品：当前商品的选中状态 店铺:店铺的选中状态
			 * curCartStoreGoodsId：商品：当前商品购物车信息 店铺：storeId
			 */
			const changeSelectState = (type, isCheckedState, curCartStoreGoodsId) => {
				//已登录
				let cartIds = ""; //商品的购物车id集

				if (type == 'spec') {
					cartIds = curCartStoreGoodsId.cartId
				} else if (type == "goods") {
					//选择商品
					let { goodsCartList } = curCartStoreGoodsId
					cartIds = goodsCartList.map(i => i.cartId).join(',')
				} else if (type == "store") {
					//选择店铺
					let { storeGoodsList } = curCartStoreGoodsId
					let acviGoodsList = storeGoodsList.map(i => i.goodsCartList).flat(1)
					cartIds = acviGoodsList.filter(i => i.productState == 1 || i.productState == 3).map(s => s.cartId).join(',')
				} else if (type == "all") {
					//全选或全不选
					let { storeCartGroupList } = cartData.data
					let arr = []
					storeCartGroupList.map((storeItem) => {
						storeItem.storeGoodsList.map((cartItem) => {
							cartItem.goodsCartList.forEach(item => {
								if (item.productState == 1 || item.productState == 3) {
									arr.push(item.cartId)
								}
							})
						});
						cartIds = arr.join(',');
					})
				}

				let params = {
					cartIds: cartIds, //购物车id集合
					checked: isCheckedState ? 0 : 1, //是否选中：0=全不选、1=全选中
				};
				proxy.$post("v3/business/front/cart/checkedCarts", params)
					.then((res) => {
						if (res.state == 200) {
							cartData.data = res.data

						} else {
							ElMessage.error(res.msg);
						}
					});
			};

			//移入收藏夹 cartId: 商品购物车id集(当前商品购物车id)
			const moveCartGoods = (cartId) => {
				let params = {
					cartIds: cartId,
				};
				proxy
					.$post("v3/business/front/cart/moveToCollection", params)
					.then((res) => {
						if (res.state == 200) {
							cartData.data = res.data;
							cartData.data = proxy.$calculateSubtotal(cartData.data);
							ElMessage.success(L["移入收藏夹成功！"]);
						} else {
							ElMessage.error(res.msg);
						}
					});
			};

			//删除商品 type:'goods'单个商品 curCartItem: 当前商品信息    batch:批量
			const delCartGoods = (type, curCartItem) => {
				//已登录
				if (type == "goods") {
					delGoodsCartIds.value = curCartItem.cartId;
					curCartItem.promotionModel = false
				} else if (type == "batch") {
					let delGoodsList = []
					cartData.data.storeCartGroupList.map((storeItem) => {
						storeItem.storeGoodsList.map((cartItem) => {
							let checkList = cartItem.goodsCartList.filter(i => i.isChecked).map(i => i.cartId)
							delGoodsList = delGoodsList.concat(checkList)
						});
					});
					delGoodsCartIds.value = delGoodsList.join(',')
				}
				chosenGoodsModel.value = false
				confirmDelGoodsModel();
			};

			//确定删除商品
			const confirmDelGoodsModel = () => {
				// 已登录
				let params = {
					cartIds: delGoodsCartIds.value,
				};
				proxy
					.$post("v3/business/front/cart/deleteCarts", params)
					.then((res) => {
						if (res.state == 200) {
							cartData.data = res.data;
							ElMessage.success(L["删除成功！"]);
							proxy.$getLoginCartListData();
						} else {
							ElMessage.error(res.msg);
						}
					});
			};

			//打开清空失效商品弹框
			const clearInvalidGoods = () => {
				clearInvalidGoodsModel.value = true;
			};
			//确定清空失效商品
			const confirmClearInvalidModel = () => {
				let params = {};
				proxy.$post("v3/business/front/cart/emptyInvalid", params).then((res) => {
					if (res.state == 200) {
						cartData.data.invalidList = [];
						ElMessage.success("清空失效商品成功！");
					} else {
						ElMessage.error(res.msg);
					}
				});
				clearInvalidGoodsModel.value = false;
			};
			//取消清空失效商品
			const cancelClearInvalidModel = () => {
				clearInvalidGoodsModel.value = false;
			};

			//去商品详情页
			const goGoodsDetail = (productId) => {
				proxy.$goGoodsDetail(productId);
			};

			//去结算
			const goBuy = () => {
				if (isSubmit.value) {
					return;
				}
				if (cartData.data.totalCheck > 0) {
					let flag = false;
					let name = '';
					let num = '';
					cartData.data.storeCartGroupList.map(item => {
						item.storeGoodsList.map(items => {
							items.goodsCartList.forEach(item2 => {
								if (item2.isChecked == 1 && item2.buyNum < item2.minBuyNum && !name) {
									flag = true;
									name = items.goodsName;
									num = items.minBuyNum;
								}
							})
						})
					})
					if (flag) {
						ElMessage.warning('商品：' + name + '起订量为' + num);
						return;
					}
					let query = {
						ifcart: true,
					}
					router.push({ path: "/buy/confirm", query });
				} else {
					ElMessage("结算商品不能为空！");
				}
			};
			//关闭登录弹框
			const closeLoingModal = () => {
				loginModalVisibleFlag.value = false;
			};


			//去首页
			const goIndex = () => {
				router.push({ path: "/index" });
			};



			const delBatchGoods = () => {
				let antiNum = 0
				let { totalCheck } = cartData.data
				if (totalCheck == 0) {
					ElMessage.warning('请选择要删除的商品')
				} else {
					chosenGoodsModel.value = true
				}
			}


			const service = (id) => { //客服
				let newWin = router.resolve({
					path: '/service',
					query: {
						vid: id
					}
				})
				window.open(newWin.href, '_blank');
			}

			//暴露的变量及方法
			return {
				L,
				token,
				cartData,
				editNum,
				clearInvalidGoods,
				clearInvalidGoodsModel,
				confirmClearInvalidModel,
				cancelClearInvalidModel,
				changeSelectState,
				confirmDelGoodsModel,
				moveCartGoods,
				delCartGoods,
				delGoodsCartIds,
				goGoodsDetail,
				goBuy,
				goIndex,
				cartListData,
				loginModalVisibleFlag,
				closeLoingModal,
				optionsBtn,
				optionsBtnTop,
				windowHeight,
				optionsBtnShow,
				curStoreId,
				elementScrollTop,
				delBatchGoods,
				chosenGoodsModel,
				service,
				firstLoading,
				skelelonData,
				memberInfo,
				isSubmit,
				totalCheck
			};
		},
	};
</script>

<style lang="scss" scoped>
	@import "../../style/base.scss";
	@import "../../style/cartIndex.scss";
</style>
<style lang="scss">
	.cart_content .el-radio {
		margin-bottom: 15px;
		white-space: unset;
		vertical-align: top;
		margin-right: 20px;
	}

	.el-button--primary {
		background-color: $colorMain;
		border-color: $colorMain;

		&:hover {
			background-color: $colorMain;
			border-color: $colorMain;
		}
	}

	.el-button--text {
		color: $colorMain;


		&:hover {
			color: $colorMain;
		}
	}

	.cart_content .el-radio__label {
		width: 240px;
		display: inline-block;
		word-break: break-all;
		vertical-align: top;
	}

	.cart_content .el-radio__input.is-checked .el-radio__inner {
		border-color: $colorMain2;
		background: $colorMain2;
	}

	.cart_content .el-radio__input.is-checked+.el-radio__label {
		color: $colorMain2;
	}

	.promotion_btn {
		span {
			width: 40px;
			height: 20px;
			background: $colorMain;
			border-radius: 3px;
			font-size: 12px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #ffffff;
			display: inline-block;
			text-align: center;
			line-height: 20px;
			font-weight: 600;
			cursor: pointer;

			&:nth-child(2) {
				margin-left: 40px;
				background: #ffffff;
				color: $colorMain;
			}
		}
	}

	.confirm_order_main {
		.store_item_bottom_item {
			.el-input__prefix {
				.el-icon-date {
					height: 34px;
					line-height: 34px;
				}
			}

			.el-input__icon,
			.el-input__suffix,
			.el-icon-circle-close {
				height: 36px;
				line-height: 36px;
			}
		}
	}

	.el-overlay {
		background-color: rgba(0, 0, 0, 0.3);
	}
</style>