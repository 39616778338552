<template>
     <div class="spec_list_con">
          <div class="goods_spec">
               <div class="goods_spec_pre flex_row_start_start" v-for="(specItem, specIndex) in specsLocal" :key="specIndex">
                    <div class="goods_spec_pre_title" :title="specItem.specName">
                         {{ specItem.specName }}
                    </div>
                    <div class="goods_spec_pre_list flex_row_start_center">
                         <!-- checkState : 1-选中，2-可选，3-禁用 -->
                         <div class="specval_pre cursor_pointer"
                              :class="{ specval_pre_disabled: specVal.checkState == 3, specval_pre_active: specVal.checkState == 1 }"
                              v-for="(specVal, sVIndex) in specItem.specValueList" :key="sVIndex"
                              @click="selectSpecVal(specItem, specVal, sVIndex)">
                              <div class="specval_pre_image" :style="{ backgroundImage: 'url(' + specVal.image + ')', }"
                                   :title="specVal.specValue" v-if="specVal.image"></div>
                              <span class="specval_pre_text" v-else>{{ specVal.specValue }}</span>
                              <i class="iconfont iconyixuan" v-if="specVal.checkState == 1"></i>
                         </div>
                    </div>
               </div>


               <div class="goods_spec_pre flex_row_start_start">
                    <div class="goods_spec_pre_title the_last" :title="lastSpecInfo.specName">
                         {{ lastSpecInfo.specName }}
                    </div>
                    <div class="last_spec_pre">
                         <table>
                              <tr class="last_spec_item " v-for="(specVal, sVIndex) in lastSpecValueList" :key="sVIndex"
                                   v-show="specVal.checkState != '3'">
                                   <td class="flex_row_start_center">
                                        <i :class="{ iconfont: true, 'icon-yuanquan1': (specVal.checkState != 1), 'icon-ziyuan33': (specVal.checkState == 1) }"
                                             @click="selectLastSpecVal(specVal, sVIndex)">
                                        </i>
                                        <div class="specval_pre" :class="{ specval_pre_active: (specVal.checkState == 1) }">

                                             <div class="specval_pre_image"
                                                  :style="{ backgroundImage: 'url(' + specVal.image + ')', }"
                                                  :title="specVal.specValue" v-if="specVal.image"></div>
                                             <span class="specval_pre_text" v-else>{{ specVal.specValue }}</span>
                                        </div>
                                   </td>

                                   <td>
                                        <div class="product_code">
                                             库存：{{ specVal.productStock }}</div>
                                   </td>


                                   <td>
                                        <div class="quantity_inventory ">
                                             <div class="flex_column_start_center">
                                                  <div class="quantity_edit flex_row_start_center">
                                                       <span @click="editNumSpec('reduce', specVal)"
                                                            :style="reduceStyle">-</span>
                                                       <input type="number" v-model="specVal.currentSpecNum"
                                                            @input="editNumSpec('edit', specVal)"
                                                            @blur="editNumSpec('blur', specVal)" />
                                                       <span @click="editNumSpec('add',specVal)"
                                                            :style="addStyle">+</span>

                                                       <div class="disable_mask" v-if="specVal.checkState != 1"></div>
                                                  </div>
                                             </div>
                                        </div>
                                   </td>

                              </tr>
                         </table>

                    </div>
               </div>
          </div>

          <div class="series flex_row_between_center" v-if="firstLoading ? true : true">
               <div class="flex_row_start_center">
                    <div>
                         <span class="series_title">合计</span>
                    </div>
                    <span :class="{ series_total: true, skeleton_series_total: firstLoading }">
                         <span style="font-size: 16px;margin-right: 1px">¥</span>
                         <span v-if="staticInfo.ladderPriceList.length">{{ totalPrice }}</span>
                         <span v-else>&nbsp;--</span>
                    </span>
               </div>
               <div class="select_vals">
                    <el-popover placement="top-end" :width="800" trigger="click" @before-enter="beforeEnter"
                         @show="beforeEnter">
                         <template #reference>
                              <div>
                                   <span>已选清单</span>
                                   <span class="el-icon-arrow-down"></span>
                              </div>
                         </template>
                         <div class="select_list">
                              <div class="flex_row_between_center" v-if="chosenSpecList.length">
                                   <div class="left_scroll">
                                        <div class="chosen_specs flex_row_start_center"
                                             v-for="(item, index) in chosenSpecList">
                                             <div class="spec_item">{{ item.getSpecValues }}</div>
                                        </div>
                                   </div>
                                   <div class="right_operate">
                                        <div class="op_item flex_row_start_center" v-for="(item2, index2) in chosenSpecList">
                                             <div class="quantity_edit flex_row_start_center">
                                                  <span @click="editNumSel('reduce', item2)" :style="reduceStyle">-</span>
                                                  <input type="number" v-model="item2.buyNum"
                                                       @input="editNumSel('edit', item2)"
                                                       @blur="editNumSel('blur', item2)" />
                                                  <span @click="editNumSel('add', item2)" :style="addStyle">+</span>
                                             </div>
                                             <div class="del_chosen" @click="delChosen(index2, item2)">删除</div>
                                        </div>
                                   </div>
                              </div>

                              <div v-else>
                                   <div class="empty flex_column_center_center">
                                        <img src="@/assets/common_empty.png">
                                        <p>暂无可选清单</p>
                                   </div>
                              </div>

                         </div>
                    </el-popover>
               </div>
          </div>
</div>
</template>


<script>
import { reactive, getCurrentInstance, ref, onMounted, provide, watch, computed } from "vue";
import { ElMessage } from 'element-plus'
export default {
     props: {
          specs: Array,
          specProductList: Array,
          defaultProduct: Object,
          staticInfo: Object
     },

     setup(props, { emit }) {
          const specListNum = ref(0)
          const chosenSpecList = ref([])
          const lastSpecObject = reactive({
               specValueId: '',
               targetProId: 0
          })
          const isLastChosen = ref(true)
          const lastSpecInfo = ref({})
          const lastSpecValueList = ref([])
          const initLadder = {
               ladderPrice: 0,
               buyNum: 1
          }

          const dfSpecValArr = ref([]),
               dfSpecValArrPop = ref(0)

          const specsLocal = ref([])

          //选中规格值
          const selectSpecVal = (specItem, specVal, specValIndex) => {

               if (specItem.checkState == 3) {
                    return
               }


               isLastChosen.value = false
               let choiceSpecIds = [];
               specsLocal.value.forEach((item) => {
                    if (item.specId != specItem.specId) {
                         item.specValueList.forEach((item1) => {
                              if (item1.checkState == "1") {
                                   // checkState: 1-选中，2-可选，3-禁用
                                   choiceSpecIds.push(item1.specValueId);
                              }
                         });
                    } else {
                         choiceSpecIds.push(specVal.specValueId);
                    }
               });
               choiceSpecIds.push(dfSpecValArrPop.value)
               lastSpecObject.targetProId = props.specProductList.find(i => i.getSpecValueIds == choiceSpecIds.join(',')).productId
               emit('selectSpecVal', lastSpecObject.targetProId)
          }

          //选中规格值(最后的规格)
          const selectLastSpecVal = (specVal, specValIndex) => {
               if (specVal.checkState == 2) {
                    let choiceSpecIds = [...dfSpecValArr.value, specVal.specValueId]
                    lastSpecObject.targetProId = props.specProductList.find(i => i.getSpecValueIds == choiceSpecIds.join(',')).productId
                    isLastChosen.value = true
                    emit('selectSpecVal', lastSpecObject.targetProId)
               } else {
                    let targetIdx = chosenSpecList.value.findIndex(i => i.productId == specVal.productId)
                    let alternaPro
                    if (targetIdx > -1) {
                         chosenSpecList.value.splice(targetIdx, 1)
                         lastSpecValueList.value.forEach(lasSpecVal => {
                              if (lasSpecVal.productId == specVal.productId) {
                                   lasSpecVal.checkState = 2
                              }
                         })

                    } else {
                         lastSpecValueList.value.map((item) => {
                              if (item.productId == chosenSpecList.value[targetIdx].productId) {
                                   item.checkState = 2
                                   item.currentSpecNum = ''
                              }
                         })
                         chosenSpecList.value.splice(targetIdx, 1)
                    }
               }
          }

          //用chosenSpecList来计算合计，考虑阶梯价
          const totalPrice = computed(() => {
               if (chosenSpecList.value.length) {
                    let totalNum = chosenSpecList.value.reduce((total, obj) => total + Number(obj.buyNum), 0)
                    let { ladderPriceList } = props.staticInfo
                    let finalPrice = ladderPriceList.reduce((prev, cur) => {
                         return totalNum >= cur.buyNum ? cur : prev
                    }, initLadder).ladderPrice
                    return Number(totalNum * finalPrice).toFixed(2)
               } else {
                    return 0
               }

          })

          onMounted(() => {
               ladderPrice()
               handleChosenSpecs()
          })


          watch(() => [props.specProductList, props.specs], () => {
               handleChosenSpecs()
          })


          const ladderPrice = () => {
               let arr = [2, 5, 10]
               let num = 6

               let res = arr.reduce((prev, cur) => {
                    return num > cur ? cur : prev
               })

          }



          //对 选中的规格值 和 规格值枚举列表比对（选中规格值组合中除开最后一个规格的规格值固定匹配）
          const handleChosenSpecs = () => {
               let { defaultProduct, specProductList } = props

               if (isLastChosen.value) {//判断已选清单里有没有该规格，没有就加进去,前提是最后一个规格选择
                    addIntoList(defaultProduct)
               }

               specsLocal.value = JSON.parse(JSON.stringify(props.specs))

               let lastSpec = specsLocal.value.pop() //取出规格列表的最后一个规格，同时规格列表也将最后一个规格去掉
               dfSpecValArr.value = defaultProduct.getSpecValueIds.split(',') //将选中规格值字符串组合分割成数组
               dfSpecValArrPop.value = dfSpecValArr.value.pop() //取出选中规格组合的最后一个规格值，同时选中规格组合列表也将最后一个规格值去掉


               //由于要给 specValueList 每个项进行重构，得拆解这最后一个规格lastSpec进行逐一赋值
               for (let key in lastSpec) {
                    if (key == 'specValueList') {
                         lastSpecValueList.value = lastSpec.specValueList.map((i, idx) => {
                              let specValCombine = dfSpecValArr.value.concat([i.specValueId]).join(',')
                              let specProInfo = specProductList.find(pro => pro.getSpecValueIds == specValCombine) //从specProductList找出该规格值对应的sku商品信息

                              //同时从已选清单里找出有没有这个sku规格,有的话将checkState置为1(选择)，并将数量同步过去，否则不是为checkState为3(失效)的话，置为2(不选择)
                              let isSpecExist = chosenSpecList.value.findIndex(i => i.getSpecValueIds == specValCombine)
                              if (isSpecExist > -1) {
                                   i.checkState = 1
                                   i.currentSpecNum = chosenSpecList.value[isSpecExist].buyNum
                              } else if (i.checkState != 3) {
                                   i.checkState = 2
                              }

                              let obj = {
                                   ...i,
                                   ...specProInfo
                              }
                              return obj
                         }) //同时将此规格下的规格值列表进行重构，每个规格值保留规格值Id和规格值名，同时 加入该规格值Id和其他规格中选中的规格值Id的组合

                    } else {
                         lastSpecInfo.value[key] = lastSpec[key]
                    }
               }

          }

          //加入已选清单
          const addIntoList = (specValueInfo) => {
               let index = chosenSpecList.value.findIndex(i => i.getSpecValueIds == specValueInfo.getSpecValueIds)
               if (index < 0) {
                    let localspecValueInfo = {
                         buyNum: 1,
                         getSpecValueIds: specValueInfo.getSpecValueIds,
                         getSpecValues: specValueInfo.getSpecValues,
                         getSpecValuesList: specValueInfo.getSpecValues.split(','),
                         productId: specValueInfo.productId,
                         productStock: specValueInfo.productStock
                    }
                    chosenSpecList.value.push(localspecValueInfo)
               }
          }


          //改变数量按钮样式（规格）
          const editNumSpec = (type, specVal) => {
               let { currentSpecNum, productStock } = specVal
               let maxBuyNum = Math.min(productStock, 9999)
               switch (type) {
                    case 'reduce': {
                         if (specVal.currentSpecNum <= 1) {
                              return
                         } else {
                              specVal.currentSpecNum--
                         }
                         break
                    }
                    case 'edit': {
                         if ((!/^[1-9]\d*$/.test(currentSpecNum) && currentSpecNum)) {
                              specVal.currentSpecNum = 1
                         } else if (specVal.currentSpecNum > maxBuyNum) {
                              specVal.currentSpecNum = maxBuyNum;
                              ElMessage('超过购买限制');
                         }
                         break
                    }
                    case 'add': {

                         specVal.currentSpecNum++
                         if (specVal.currentSpecNum > maxBuyNum) {
                              specVal.currentSpecNum = maxBuyNum;
                              ElMessage('超过购买限制');
                         }
                         break;
                    }
                    case 'blur': {
                         if (specVal.currentSpecNum <= 1) {
                              specVal.currentSpecNum = 1
                              return;
                         }
                         break;
                    }
               }
               //对已选清单的对应的规格进行数量同步
               if (currentSpecNum) {
                    synchronizeBuyNum(specVal, 'specVal')
               }
          };


          //数量同步
          const synchronizeBuyNum = (spec, type) => {
               switch (type) {
                    case 'specVal': {
                         let targetIdx = chosenSpecList.value.findIndex(k => k.productId == spec.productId)
                         chosenSpecList.value[targetIdx].buyNum = spec.currentSpecNum
                         break
                    }

                    case 'specChosen': {
                         let targetIdx = lastSpecValueList.value.findIndex(k => k.productId == spec.productId)
                         lastSpecValueList.value[targetIdx].currentSpecNum = spec.buyNum
                         break
                    }
               }
          }

          const returnChosenSpec = () => {
               let isLastChosen = lastSpecValueList.value.some(i => i.checkState == 1)
               return { isLastChosen, chosenSpecList: chosenSpecList.value }
          }


          //改变数量按钮样式(已选清单规格)
          const editNumSel = (type, item2) => {
               let { productStock } = item2
               let maxBuyNum = Math.min(productStock, 9999)
               switch (type) {
                    case 'reduce': {
                         if (item2.buyNum <= 1) {
                              return
                         } else {
                              item2.buyNum--
                         }
                         break
                    }
                    case 'edit': {
                         if ((!/^[1-9]\d*$/.test(item2.buyNum) && item2.buyNum)) {
                              item2.buyNum = 1
                         } else if (item2.buyNum > maxBuyNum) {
                              item2.buyNum = maxBuyNum;
                              ElMessage('超过购买限制');
                         }
                         break
                    }
                    case 'add': {
                         item2.buyNum++
                         if (item2.buyNum > maxBuyNum) {
                              item2.buyNum = maxBuyNum;
                              ElMessage('超过购买限制');
                         }
                         break;
                    }
                    case 'blur': {
                         if (item2.buyNum <= 1) {
                              item2.buyNum = 1
                              return;
                         }
                         break;
                    }
               }

               //规格选择的对应的规格进行数量同步
               if (item2.buyNum) {
                    synchronizeBuyNum(item2, 'specChosen')
               }
          };

          const delChosen = (index, obj) => {
               lastSpecValueList.value.map((item) => {
                    if (item.productId == obj.productId) {
                         item.checkState = 2
                         item.currentSpecNum = ''
                    }
               })
               chosenSpecList.value.splice(index, 1)
          }

          const beforeEnter = (e) => {

          }

          onMounted(() => {
          })
          return {
               beforeEnter,
               specListNum,
               chosenSpecList,
               selectSpecVal,
               selectLastSpecVal,
               lastSpecObject,
               isLastChosen,
               editNumSpec,
               totalPrice,
               editNumSel,
               delChosen,
               returnChosenSpec,
               lastSpecValueList,
               lastSpecInfo,
               specsLocal
          }
     }
}
</script>


<style lang="scss">
@import "@/style/goodsDetail.scss";

.empty {
     padding: 15px 0;

     img {
          width: 100px;
          height: 100px;
     }
}


.spec_list_con {
     padding-bottom: 10px;
     border-bottom: 1px dashed #ccc;
     flex: 1;
     width: 100%;
}
</style>