// 商品详情页面
<template>
	<StoreHeaderCat ref="headercat" @updateFllow="updateFllow" :vid="vid" />
	<div class="sld_goods_detail">

		<!-- 内容区顶部固定 start -->
		<div class="contain_con" v-if="containCon">
			<div class="contain_content flex_row_center_center">
				<div class="contain_con_left flex_row_start_center">
					<span class="store_type" v-if="goodsDetail.data.storeInf.isOwnStore == 1">{{ L["自营"] }}</span>
					<span class="store_title">{{
						goodsDetail.data.storeInf.storeName
					}}</span>
					<img src="@/assets/goods/link_server.png" @click="kefu" />
				</div>
				<div class="contain_con_right">
					<div class="goods_description_title flex_row_between_center">
						<div class="description_title_left flex_row_start_center">
							<span class="cursor_pointer"
								:class="{ description_active: goodsDesctionType == 'detail' }"
								@click="goodsDescType('detail')">{{ L["商品详情"] }}</span>
							<span class="cursor_pointer"
								:class="{ description_active: goodsDesctionType == 'evaluate' }"
								@click="goodsDescType('evaluate')">{{ L["评价"] }}({{
									goodsCommentsInfo.data &&
									goodsCommentsInfo.data.commentsCount > 0
									? goodsCommentsInfo.data.commentsCount
									: "0"
								}})</span>
							<span class="cursor_pointer"
								:class="{ description_active: goodsDesctionType == 'service' }"
								@click="goodsDescType('service')">{{ L["商品服务"] }}</span>
							<span class="cursor_pointer" :class="{
								description_active: goodsDesctionType == 'salestore',
							}" @click="goodsDescType('salestore')">供应商热销</span>
						</div>
						<div class="description_title_right flex_row_end_center">
						<!-- <div class="go_add_cart cursor_pointer" @click="addCart">
								{{ L["加入购物车"] }}
													</div> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 内容区顶部固定 end -->

		<div class="goods_detail_content" v-if="goodsDetail.data">
			<!-- 商品所属分类 ，联系客服，关注店铺 start-->
			<div class="goods_about_con">
				<div class="goods_about flex_row_between_center">
					<div class="goods_classify">
						<span v-for="(item, index) in goodsDetail.data.categoryPath" :key="index">
							<router-link
								:to="`/goods/list?categoryId=${goodsDetail.data.categoryIdPath[index]}&g=${index + 1}${index > 0 ? '&pid=' + goodsDetail.data.categoryIdPath[index - 1] : '&pid=0'}`">
								{{ item }}</router-link>
							<i> > </i>
						</span>
						<span style="color: #333333;">{{ goodsDetail.data.goodsName }}</span>
					</div>
					<div class="goods_about_right flex_row_between_center">
						<router-link target="_blank" :to="`/store/index?vid=${goodsDetail.data.storeInf.storeId}`"
							class="goods_about_store flex_row_between_center" v-if="goodsDetail.data.storeInf">
							<span>{{ goodsDetail.data.storeInf.storeName }}</span>
							<span v-if="goodsDetail.data.storeInf && goodsDetail.data.storeInf.isOwnStore == '1'">{{
								L["自营"]
							}}</span>
						</router-link>
						<div class="contact_service flex_row_between_center" @click="kefu">
							<span>{{ L["联系客服"] }}</span>
							<img src="@/assets/goods/server.png" @click="kefu" :title="L['联系客服']" />
						</div>
					</div>
				</div>
			</div>
			<!-- 商品所属分类 ，联系客服，关注店铺 end-->




			<!-- 商品主要信息 start -->
			<div class="main_con">

				<!-- 商品相关 start -->
				<div class="goods_des">
					<mainImage :defaultProduct="goodsDetail.data.defaultProduct"
						:goodsVideo="goodsDetail.data.goodsVideo" :firstLoading="firstLoading">
					</mainImage>
					<!-- 商品详细信息 start -->
					<div class="m_item_inner">
						<div class="item_info">
							<div :class="{ detaile_name: true, skeleton_detaile_name: firstLoading }">
								{{ goodsDetail.data.goodsName }}
							</div>
							<div :class="{ p_ad: true, skeleton_p_ad: firstLoading }"
								v-if="goodsDetail.data.goodsBrief">
								{{ goodsDetail.data.goodsBrief }}
							</div>
							<!-- 商品未下架即正常商品 start -->
							<div v-if="goodsDetail.data.state == 3 || firstLoading">

								<div class="summary">
									<div class="sld_summary_item summary_goods clearfix"
										v-if="goodsDetail.data.defaultProduct">
										<div class="sld_summary_goods_left">
											<div class="goods_price flex_row_start_center">
												<!-- 在售价 -->
												<div class="flex_row_start_center"
													v-if="firstLoading ? true : goodsDetail.data.ladderPriceList && goodsDetail.data.ladderPriceList.length">

													<div class="flex_column_center_start">
														<span class="price_title">{{ L["价格"] }}</span>
														<span class="price_title">起批量</span>
													</div>
													<div class="price_list">
														<el-scrollbar>
															<div class="flex_row_start_center">
																<div class="flex_column_center_center"
																	v-for="(price, index) in goodsDetail.data.ladderPriceList"
																	:key="index"
																	style="margin-right: 15px;height: 60px">
																	<strong
																		:class="{ p_price: true, skeleton_p_price: firstLoading, }">
																		￥{{ price.ladderPrice }}
																	</strong>
																	<div class="buyNum">
																		≥{{ price.buyNum }}
																	</div>
																</div>
															</div>
														</el-scrollbar>
													</div>
												</div>

												<div class="flex_row_start_center" v-else>
													<span class="price_title">{{ L["价格"] }}</span>
													<strong
														:class="{ p_price: true, skeleton_p_price: firstLoading }">
														￥ ----
													</strong>
												</div>
											</div>
										</div>
									</div>
								</div>


								<div v-if="(!firstLoading) && goodsDetail.data.deliverInfo">
									<addressCon :productId="productId" @expressFee="expressFee"
										@currentAddr="currentAddr"></addressCon>
								</div>

								<!-- 运费 start -->
								<div v-if="!firstLoading" class="freight flex_row_start_center">
									<div class="freight_title">{{ L["运费"] }}</div>
									<span :class="{ freight_amount: firstLoading }" v-if="
										goodsDetail.data &&
										goodsDetail.data.deliverInfo &&
										goodsDetail.data.deliverInfo.expressFee
									">￥{{ goodsDetail.data.deliverInfo.expressFee }}</span>
									<span v-else>{{ L['免运费'] }}</span>
								</div>

								<!-- 多规格 start -->
								<specComponent :specs="specList" :specProductList="specProductList"
									:defaultProduct="goodsDetail.data.defaultProduct" @selectSpecVal="selectSpecVal"
									:staticInfo="goodsDetail.data" ref="spec"
									v-if="!firstLoading && specProductList.length">
								</specComponent>
								<!-- 多规格 end -->

								<!-- 单规格数量start -->
								<template v-else>
									<!-- 数量加减及库存 start -->
									<div v-if="!firstLoading" class="quantity_inventory flex_row_start_center">
										<div class="quantity_title">{{ L["数量"] }}</div>
										<div class="quantity_edit flex_row_start_center">
											<span @click="editNum('reduce')" ref="reduce"
												:style="disStyle">-</span>
											<input type="number" v-model="currentSpecNum"
												@input="editNum('edit', cartItem, $event)"
												@blur="editNum('blur', $event)" />
											<span @click="editNum('add')" ref="add">+</span>
										</div>
										<div class="inventory"
											v-if="goodsDetail.data.defaultProduct && goodsDetail.data.defaultProduct.productStock">
											{{ L["库存"]
											}}{{ goodsDetail.data.defaultProduct.productStock }}件
										</div>
									</div>
									<!-- 数量加减及库存 end-->

									<div class="series flex_row_between_center" v-if="firstLoading ? true : true">
										<div class="flex_row_start_center">
											<div>
												<span class="series_title">合计</span>
											</div>
											<span
												:class="{ series_total: true, skeleton_series_total: firstLoading }">
												<span style="font-size: 16px;margin-right: 1px">¥</span>
												<span
													v-if="goodsDetail.data.ladderPriceList && goodsDetail.data.ladderPriceList.length">{{
														totalPrice || 0
													}}</span>
												<span v-else>&nbsp;--</span>
											</span>
										</div>
									</div>
								</template>
								<!-- 单规格数量end -->
							</div>
							<!-- 商品未下架即正常商品 end -->
							<template v-if="!firstLoading">
								<!-- 立即购买 加入购物车 收藏 ，分享 start-->
								<!-- 商品已下架 start -->
								<div class="options_btn" v-if="goodsDetail.data.state != 3">
									<p class="option_desc">商品已下架，欢迎挑选其他商品~</p>
									<div class="goods_off_shelves">{{ L["商品已下架"] }}</div>
									<!-- 商品下架时的推荐商品 start -->
									<div class="recoOffShop">
										<div class="reCon"
											v-for="({ mainImage, goodsName, wholesaleStepPrice, productId }, index) in recomOffShop.data"
											:key="index">
											<div class="reComImg">
												<router-link :to="`/goods/detail?productId=${productId}`"
													target="_blank"> <img :src="mainImage" alt="">
												</router-link>
											</div>
											<router-link class="recomName"
												:to="`/goods/detail?productId=${productId}`" target="_blank">
												{{ goodsName }}
											</router-link>
											<span class="recomPrice"
												v-if="goodsDetail.data.cooperate == 4 || goodsDetail.data.cooperate == 0">￥{{
													wholesaleStepPrice
												}}</span>
											<span class="recomPrice" v-else>￥&nbsp;--</span>
										</div>
									</div>
									<!-- 商品下架时的推荐商品 end -->
								</div>
								<!-- 商品已下架 end -->
								<!-- 商品库存不足 start -->
								<div class="options_btn flex_row_between_center"
									v-else-if="!goodsDetail.data.defaultProduct.productStock">
									<div class="goods_out_stock">{{ L["库存不足"] }}</div>
								</div>
								<!-- 商品库存不足 end -->
								<!-- 普通(活动)正常商品 start -->
								<div class="options_btn flex_row_start_center" v-else>
									<div class="flex_row_start_center"
										v-if="goodsDetail.data.cooperate == 4 || goodsDetail.data.cooperate == 0">
										<div class="buy_now flex_row_center_center" @click="goBuy">
											{{ L["立即购买"] }}
										</div>

										<div class="add_cart flex_row_center_center" @click="goConsignment"
											style="margin-right:20px" v-if="!goodsDetail.data.importState">
											<span>立即代销</span>
										</div>





										<div class="add_cart flex_row_center_center" @click="addCart"
											v-if="goodsDetail.data.isVirtualGoods == 1">
											<img src="@/assets/goods/cart.png" alt="" />
											<span>{{ L["加入购物车"] }}</span>
										</div>
									</div>
									<div v-else>
										<div class="add_cart flex_row_center_center" @click="applyCoorpFuc"
											v-if="goodsDetail.data.cooperate == 1 || goodsDetail.data.cooperate == 2">
											申请合作
										</div>
										<div class="add_cart flex_row_center_center"
											v-if="goodsDetail.data.cooperate == 3" style="cursor: default;">
											申请审核中
										</div>
										<div class="add_cart flex_row_center_center"
											v-if="goodsDetail.data.cooperate == 5" style="cursor: default;">
											申请合作禁止
										</div>
									<!-- <div class="add_cart flex_row_center_center"
											v-if="goodsDetail.data.cooperate==2" style="cursor: default;">
											申请审核拒绝
																</div> -->
									</div>
								</div>
								<!-- 普通(活动)正常商品 end -->
								<!-- 立即购买 加入购物车 收藏 ，分享 end-->
							</template>
						</div>
					</div>
					<!-- 商品详细信息 end -->
				</div>
				<!-- 商品相关 end -->
				<!-- 店铺，及各种信息的切换 start -->
				<div class="container flex_row_start_start" ref="container" id="container">
					<div class="left">
						<div class="store_info" v-if="goodsDetail.data.storeInf">
							<div class="store_info_title flex_row_start_center">
								<span class="store_type" v-if="goodsDetail.data.storeInf.isOwnStore == 1">{{
									L["自营"]
								}}</span>
								<span class="store_title" @click="goStore()">{{
									goodsDetail.data.storeInf.storeName
								}}</span>
								<img src="@/assets/goods/link_server.png" @click="kefu" />
							</div>
							<div class="store_des">

								<div class="store_des_pre">
									<span>{{ L["商品评价"] }}</span>
									<span
										:class="goodsDetail.data.storeInf.descriptionScore < 2 ? 'evaluationes' : ''">{{
											goodsDetail.data.storeInf.descriptionScore
										}}</span>
									<span v-if="goodsDetail.data.storeInf.descriptionScore > 4">{{
										L["高"]
									}}</span>
									<span v-else-if="goodsDetail.data.storeInf.descriptionScore < 2"
										class="evaluationes">{{ L["低"]
										}}</span>
									<span v-else>{{ L["中"] }}</span>
								</div>
								<div class="store_des_pre pre_deliver">
									<span>{{ L["物流评价"] }}</span>
									<span :class="goodsDetail.data.storeInf.deliverScore < 2 ? 'evaluationes' : ''">{{
										goodsDetail.data.storeInf.deliverScore
									}}</span>
									<span v-if="goodsDetail.data.storeInf.deliverScore > 4">{{
										L["高"]
									}}</span>
									<span v-else-if="goodsDetail.data.storeInf.deliverScore < 2"
										class="evaluationes">{{
											L["低"]
										}}</span>
									<span v-else>{{ L["中"] }}</span>
								</div>
								<div class="store_des_pre pre_service">
									<span>{{ L["售后服务"] }}</span>
									<span :class="goodsDetail.data.storeInf.serviceScore < 2 ? 'evaluationes' : ''">{{
										goodsDetail.data.storeInf.serviceScore
									}}</span>
									<span v-if="goodsDetail.data.storeInf.serviceScore > 4">{{ L["高"] }}</span>
									<span v-else-if="goodsDetail.data.storeInf.serviceScore < 2"
										class="evaluationes">{{
											L["低"]
										}}</span>
									<span v-else>{{ L["中"] }}</span>
								</div>
							</div>
							<div class="store_btn flex_row_center_center">
								<div class="store_btn_pre flex_row_center_center">
									<img src="@/assets/goods/store.png" alt="" class="btn_pre_img" />
									<router-link target="_blank"
										:to="`/store/index?vid=${goodsDetail.data.storeInf.storeId}`"
										class="go_store_btn">
										进入供应商
									</router-link>
								</div>

							</div>
						</div>
						<!-- 店铺推荐及热门收藏 start -->
						<div class="store_popular">
							<div class="store_popular_btn flex_row_between_center">
								<span @click="getStorePopular('recommend')" :class="{
									store_popular_active: storePopularType == 'recommend',
								}">供应商热销</span>
							<!-- <span @click="getStorePopular('collection')" :class="{
							store_popular_active: storePopularType == 'collection',
												   }">{{ L["热门收藏"] }}</span> -->
							</div>
							<div class="store_popular_list" v-if="storePopularList.data.length">
								<div class="store_popular_pre"
									v-for="(storePopularItem, storePopularIndex) in storePopularList.data"
									:key="storePopularIndex" @click="goGoodsDetail(storePopularItem.productId)">
									<div class="store_popular_img flex_row_center_center">
										<img :src="storePopularItem.mainImage"
											:title="storePopularItem.goodsName" />
									</div>
									<p>{{ storePopularItem.goodsName }}</p>
									<p class="flex_row_between_center">
										<span>{{ L["已售"] }}{{ storePopularItem.actualSales }}</span>
										<span>￥{{ storePopularItem.wholesaleStepPrice || '--' }}</span>
									</p>
								</div>
							</div>
							<div class="popular_list_empty flex_row_center_center"
								v-show="!storePopularList.data.length">
								<p>[{{ L['暂无数据'] }}]</p>
							</div>
						</div>
						<!-- 店铺推荐及热门收藏 end -->
					</div>
					<!-- 商品详情 评价 商品服务 店铺热销  start-->
					<div class="goods_description">
						<div class="goods_description_title flex_row_between_center">
							<div class="description_title_left flex_row_start_center">
								<span class="cursor_pointer"
									:class="{ description_active: goodsDesctionType == 'detail' }"
									@click="goodsDescType('detail')">{{ L["商品详情"] }}</span>
								<span class="cursor_pointer" :class="{
									description_active: goodsDesctionType == 'evaluate',
								}" @click="goodsDescType('evaluate')">{{ L["评价"] }}({{
	goodsCommentsInfo.data &&
	goodsCommentsInfo.data.commentsCount > 0
	? goodsCommentsInfo.data.commentsCount
	: "0"
}})</span>
								<span class="cursor_pointer" :class="{
									description_active: goodsDesctionType == 'service',
								}" @click="goodsDescType('service')">{{ L["商品服务"] }}</span>
								<span class="cursor_pointer" :class="{
									description_active: goodsDesctionType == 'salestore',
								}" @click="goodsDescType('salestore')">供应商热销</span>
								<!-- 改为店铺推荐  -->
							</div>
							<div class="description_title_right flex_row_end_center">
							<!-- <div class="go_add_cart cursor_pointer" @click="addCart"
									v-if="goodsDetail.data.isVirtualGoods==1&&!goodsDetail.data.defaultProduct.promotionType">
									{{ L["加入购物车"] }}
														</div> -->
							</div>
						</div>
						<div class="goods_description_con">
							<!-- 商品详情,规格参数,及详情富文本 start-->
							<div class="goods_des_con" v-if="goodsDesctionType == 'detail'">
								<div v-if="
									goodsDetail.data.brandName ||
									(goodsDetail.data.goodsParameterList &&
										goodsDetail.data.goodsParameterList.length > 0) || goodsDetail.data.goodsDetails || goodsDetail.data.goodsFullDetails || (goodsDetail.data.attributeList &&
											goodsDetail.data.attributeList.length > 0)
								">
									<div class="brand" v-if="goodsDetail.data.brandName">
										<span>{{ L["品牌"] }}: </span>
										<span>{{ goodsDetail.data.brandName }}</span>
									</div>


									<div
										v-if="goodsDetail.data.goodsParameterList && goodsDetail.data.goodsParameterList.length > 0">
										<div class="goods_parameter_list"
											:class="{ 'goods_paramter_more': !parameterShow }">
											<div class="goods_parameter_pre"
												v-for="(parameterItem, paramterIndex) in goodsDetail.data.goodsParameterList"
												:key="paramterIndex">
												<span>{{ parameterItem.parameterName }}: </span>
												<span>{{ parameterItem.parameterValue }}</span>
											</div>
										</div>
										<div class="collapse_unfold flex_row_center_center cursor_pointer"
											v-if="goodsDetail.data.goodsParameterList.length > 16"
											@click="openParameter">
											<span>{{ !parameterShow ? L["查看全部"] : L['收起全部'] }}</span>
											<i class="iconfont icon-ziyuan11-copy" v-if="!parameterShow"></i>
											<i class="iconfont icon-ziyuan11-copy-copy" v-else></i>
										</div>
									</div>


									<div v-if="goodsDetail.data.attributeList && goodsDetail.data.attributeList.length > 0"
										style="margin-top:20px">
										<div class="goods_parameter_list"
											:class="{ 'goods_paramter_more': !attrShow }">
											<div class="goods_parameter_pre"
												v-for="(attr, attrIdx) in goodsDetail.data.attributeList"
												:key="attrIdx">
												<span>{{ attr.attributeName }}: </span>
												<span>{{ attr.attributeValue }}</span>
											</div>
										</div>
										<div class="collapse_unfold flex_row_center_center cursor_pointer"
											v-if="goodsDetail.data.attributeList.length > 16"
											@click="attrShow = !attrShow">
											<span>{{ !attrShow ? L["查看全部"] : L['收起全部'] }}</span>
											<i class="iconfont icon-ziyuan11-copy" v-if="!attrShow"></i>
											<i class="iconfont icon-ziyuan11-copy-copy" v-else></i>
										</div>
									</div>



									<div v-if="goodsDetail.data.topTemplateContent != undefined && goodsDetail.data.topTemplateContent"
										class="goods_html" :style="goodsDetail.data.topTemplateContentBg">
										<div class="goods_htmls" v-html="goodsDetail.data.topTemplateContent">
										</div>
									</div>
								<!-- <div v-if="goodsDetail.data.goodsDetails!=undefined && goodsDetail.data.goodsDetails"
										class="goods_html" :style="goodsDetail.data.goodsDetailsBg">
										<div class="goods_htmls" v-html="goodsDetail.data.goodsDetails"></div>
															</div> -->
									<div v-if="goodsDetail.data.bottomTemplateContent != undefined && goodsDetail.data.bottomTemplateContent"
										class="goods_html" :style="goodsDetail.data.bottomTemplateContentBg">
										<div class="goods_htmls" v-html="goodsDetail.data.bottomTemplateContent">
										</div>
									</div>
									<div v-if="goodsDetail.data.goodsFullDetails != undefined && goodsDetail.data.goodsFullDetails"
										class="goods_html" :style="goodsDetail.data.goodsFullDetailsBg">
										<div class="goods_htmls" v-html="goodsDetail.data.goodsFullDetails">
										</div>
									</div>
								</div>
								<div v-else>
									<SldCommonEmpty :tip="L['该商品暂无详情~']" totalWidth="934px" />
								</div>
							</div>
							<!-- 商品详情,规格参数,及详情富文本 end-->
							<!-- 商品评价 start -->
							<div class="goods_comments" v-if="goodsDesctionType == 'evaluate'">
								<div class="goods_comments_top">
									<div class="goods_comments_left">
										<div class="comments_title flex_row_between_center">
											<div class="comments_tips">{{ L["评分"] }}:</div>
											<el-rate disabled :colors="colors" score-template="{score}"
												v-model="score">
											</el-rate>
										</div>
										<div class="good_comment flex_row_center_center">
											<span>{{ L["好评率"] }}:</span>
											<span>{{ goodsCommentsInfo.data.highPercent }}</span>
										</div>
									</div>
									<div class="goods_comments_nav">
										<div class="comments_nav_pre"
											:class="{ comments_nav_pre_active: evaluationType == '' }"
											@click="getEvaluation('')">
											{{ L["全部"] }}({{
												goodsCommentsInfo.data.commentsCount
											}})
										</div>
										<div class="comments_nav_pre" :class="{
											comments_nav_pre_active: evaluationType == 'hasPic',
										}" @click="getEvaluation('hasPic')">
											{{ L["有图"] }}({{ goodsCommentsInfo.data.hasPicCount }})
										</div>
										<div class="comments_nav_pre" :class="{
											comments_nav_pre_active: evaluationType == 'high',
										}" @click="getEvaluation('high')">
											{{ L["好评"] }}({{ goodsCommentsInfo.data.highCount }})
										</div>
										<div class="comments_nav_pre" :class="{
											comments_nav_pre_active: evaluationType == 'middle',
										}" @click="getEvaluation('middle')">
											{{ L["中评"] }}({{ goodsCommentsInfo.data.middleCount }})
										</div>
										<div class="comments_nav_pre" :class="{
											comments_nav_pre_active: evaluationType == 'low',
										}" @click="getEvaluation('low')">
											{{ L["差评"] }}({{ goodsCommentsInfo.data.lowCount }})
										</div>
									</div>
								</div>
								<div class="goods_comments_list" v-if="
									goodsCommentsInfo.data &&
									goodsCommentsInfo.data.list &&
									goodsCommentsInfo.data.list.length > 0
								">
									<div class="goods_comments_pre flex_row_start_start" v-for="(
													  goodsCommentsItem, goodsCommentsIndex
													) in goodsCommentsInfo.data.list" :key="goodsCommentsIndex">
										<div class="goods_comments_avatar" :style="{
											backgroundImage:
												'url(' + goodsCommentsItem.memberAvatar + ')',
										}"></div>
										<div class="list_des">
											<div class="list_top flex_row_between_start">
												<div class="list_left">
													<div class="list_top_tile flex_row_center_center">
														<div class="list_top_tips">
															{{ goodsCommentsItem.memberName }}
														</div>
														<el-rate v-model="goodsCommentsItem.score" disabled
															text-color="#ff9900" allow-half="true">
														</el-rate>
													</div>
													<div class="spec">
														{{ goodsCommentsItem.specValues }}
													</div>
												</div>
												<div class="list_top_right">
													{{ goodsCommentsItem.createTime }}
												</div>
											</div>
											<div class="list_pre">
												<p class="CommentContent">{{ goodsCommentsItem.content }}
												</p>
												<div class="list_pre_img_list flex_row_start_center"
													v-if="goodsCommentsItem.images.length">
													<div class="imgCon1"
														v-for="(commentsImgItem, commentsImgIndex) in goodsCommentsItem.images"
														:key="commentsImgIndex">
														<div class="list_pre_img"
															:style="{ backgroundImage: 'url(' + commentsImgItem + ')', }"
															@click="showImg(commentsImgIndex, commentsImgItem)">
														</div>
													</div>
												</div>
												<p v-if="goodsCommentsItem.replyContent" class="replyContent">
													<span class="seller">商家回复:</span>
													<span class="contentRe">{{
														goodsCommentsItem.replyContent
													}}</span>
												</p>
											</div>
										</div>
									</div>
								</div>
								<div class="flex_row_end_center sld_pagination sld_page_bottom"
									v-if="goodsCommentsInfo.data.pagination.total">
									<el-pagination @current-change="handleCurrentChange"
										v-model:currentPage="evaluationCurrent" :page-size="evaluationPageSize"
										layout="prev, pager, next, jumper"
										:total="goodsCommentsInfo.data.pagination.total"
										:hide-on-single-page="true">
									</el-pagination>
								</div>
								<div v-if="
									goodsCommentsInfo.data.list &&
									goodsCommentsInfo.data.list.length == 0
								" class="flex_column_center_center empty_data">
									<SldCommonEmpty :tip="L['该商品暂无评论~']" totalWidth="934px" />
								</div>
							</div>
							<!-- 商品评价 end -->
							<!-- 商品服务 start -->
							<div class="goods_server_list" v-if="goodsDesctionType == 'service'">
								<div v-if="
									goodsDetail.data.serviceLabels &&
									goodsDetail.data.serviceLabels.length > 0
								">
									<div class="goods_server_pre" v-for="(serverItem, serverIndex) in goodsDetail.data
										.serviceLabels" :key="serverIndex">
										<div class="server_pre_top flex_row_start_center">
											<span class="server_pre_tips"></span>
											<span class="server_pre_name">{{
												serverItem.labelName
											}}</span>
										</div>
										<div class="server_pre_content">
											{{ serverItem.description }}
										</div>
									</div>
								</div>
								<div v-else>
									<SldCommonEmpty :tip="L['暂无商品服务~']" totalWidth="934px" />
								</div>
							</div>
							<!-- 商品服务 end -->
							<!-- 店铺推荐 start -->
							<div class="store_hot_sales" v-if="goodsDesctionType == 'salestore'">
								<div v-if="recommendedList.data && recommendedList.data.length > 0">
									<div class="store_hot_sales_list">
										<div class="goods_pre flex_column_between_start" v-for="(
													    recommendItem, recommendIndex
													  ) in recommendedList.data" :key="recommendIndex" @click="goGoodsDetail(recommendItem.productId)">
											<div class="flex_column_start_start">
												<div class="goods_pre_img" :style="{
													backgroundImage:
														'url(' + recommendItem.mainImage + ')',
												}"></div>
												<div class="goods_name">
													{{ recommendItem.goodsName }}
												</div>
											</div>
											<div class="goods_price">
												<div class="selling_price">
													￥{{ recommendItem.wholesaleStepPrice || '--' }}
												</div>
											</div>
										</div>
									</div>
									<div class="flex_row_end_center sld_pagination sld_page_bottom" v-if="
										recommendedList.data.pagination &&
										recommendedList.data.pagination.total
									">
										<el-pagination @current-change="handleCurrentChangeSales"
											v-model:currentPage="salesCurrent" :page-size="salesPageSize"
											layout="prev, pager, next, jumper"
											:total="recommendedList.data.pagination.total"
											:hide-on-single-page="true">
										</el-pagination>
									</div>
								</div>
								<div v-if="
									recommendedList.data && recommendedList.data.length == 0
								" class="flex_column_center_center empty_data">
									<SldCommonEmpty :tip="L['暂无相关商品~']" totalWidth="934px" />
								</div>
							</div>
							<!-- 店铺推荐 end -->
						</div>
					</div>
					<!-- 商品详情 评价 商品服务 店铺热销  end-->
				</div>
				<!-- 店铺，及各种信息的切换 end -->
			</div>
			<!-- 商品主要信息 end -->
		</div>
		<applyCoorp ref="applyCoorp" @update="applyUpdate"></applyCoorp>
		<consignment ref="consignment" @update="consignUpdate" :goodsId="goodsDetail.data.goodsId"
			:chosenSpecList="chosenSpecList" :productId="productId" :retailPrice="goodsDetail.data.retailPrice">
		</consignment>
		<SldLoginModal :visibleFlag="loginModalVisibleFlag" @closeLoingModal="closeLoingModal"
			@refreshInfo="refreshInfo" />
	</div>
</template>

<script>
import { reactive, getCurrentInstance, ref, onMounted, provide, watch, computed, defineAsyncComponent, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ElMessage, ElRate } from "element-plus";
import { qrcanvas } from "qrcanvas";
import StoreHeaderCat from "../store/StoreHeaderCat";
import { useStore } from "vuex";
import SldLoginModal from "../../components/loginModal";
import SldCommonEmpty from "../../components/SldCommonEmpty";
import mainImage from './components/mainImage';
import addressCon from './components/addressCon'
import applyCoorp from './components/applyCoorp'
import consignment from './components/consignment'
import specComponent from './components/spec'
export default {
	name: "goods-detail",
	components: {
		ElRate,
		StoreHeaderCat,
		SldLoginModal,
		SldCommonEmpty,
		mainImage,
		addressCon,
		specComponent,
		applyCoorp,
		consignment
	},
	setup() {
		const firstLoading = ref(true); //是否第一次加载
		const router = useRouter();
		const route = useRoute();
		const store = useStore();
		const vid = ref(0); //店铺id
		const { proxy } = getCurrentInstance();
		const L = proxy.$getCurLanguage();
		const goodsDetail = reactive({ data: {} }); //商品详情数据
		const productId = ref(""); //货品id
		const currentSpecNum = ref(1); //商品编辑数量，默认数量为1
		const cartListData = reactive({ data: store.state.cartListData }); //获取vux的store中的购物车数据
		const loginModalVisibleFlag = ref(false); //登录弹框是否显示，默认不显示
		const container = ref(null); // 商品详情页底部内容区
		const containerTop = ref(0); //商品详情页底部内容区的top值
		const containCon = ref(false); //固定内容区头部
		const parameterShow = ref(false); //规格参数查看是否查看全部，默认为否
		const score = ref(0) //好评率
		const colors = ref(['#E2231A', '#E2231A', '#E2231A'])//星星颜色
		const curAddr = ref('')
		const logFlag = ref(store.state.loginFlag)
		const address_list = reactive({ data: [] })
		const defaultProduct = ref({})
		const specList = ref([])
		const specProductList = ref([])
		const specComponent = null
		const storePopularList = reactive({ data: [] })
		const evaluationType = ref(""); //商品评价类型,默认显示全部
		const goodsDesctionType = ref("detail"); //商品详情,评价,商品服务,店铺热销,默认显示商品详情
		const evaluationCurrent = ref(1); //评价列表默认第一页
		const evaluationPageSize = ref(5); //评价列表默认一页显示5条数据
		const salesCurrent = ref(1); //店铺热销列表默认一页
		const salesPageSize = ref(20); //店铺热销列表默认一页显示20条数据
		const recommendeData = reactive({ data: {} }); //店铺热销推荐数据
		const goodsCommentsInfo = reactive({ data: {} }); //商品评价信息
		const recommendedList = reactive({ data: [] }); //店铺推荐列表
		const storePopularType = ref("recommend"); //店铺推荐及热门收藏,默认显示店铺推荐
		const attrShow = ref(false)
		const chosenSpecList = ref([])
		const totalPrice = computed(() => {
			let { ladderPriceList } = goodsDetail.data
			if (ladderPriceList && ladderPriceList.length && currentSpecNum.value) {
				let finalPrice = ladderPriceList.reduce((prev, cur) => {
					return currentSpecNum.value >= cur.buyNum ? cur : prev
				}, 0).ladderPrice
				return Number(currentSpecNum.value * finalPrice).toFixed(2)
			} else {
				return 0
			}

		})


		onMounted(() => {
			getInitDataStatic(route.query.productId);
			productId.value = route.query.productId;
			containerTop.value = offset(container.value).top;
			window.addEventListener("scroll", scrollHandle); //绑定页面滚动事件
		});

		const scrollHandle = async (e) => {
			let elementScrollTop = e.srcElement.scrollingElement.scrollTop; //获取页面滚动高度
			if (document.getElementById('container') && elementScrollTop > document.getElementById('container').offsetTop) {
				containCon.value = true;
				await proxy.$nextTick()
			} else {
				containCon.value = false;
			}
		};


		//获取商品详情数据
		const getInitDataStatic = (proId) => {
			let params = {
				productId: proId,
			};
			proxy.$get("v3/goods/front/goods/detail", params).then((res) => {
				if (res.state == 200) {
					goodsDetail.data = res.data
					goodsDetail.data.categoryPath = res.data.categoryPath.split('->')
					goodsDetail.data.categoryIdPath = [res.data.categoryId1, res.data.categoryId2, res.data.categoryId3]
					handleTemplate()
					if (goodsDetail.data.state != 2) {
						getRecom()
					}
					vid.value = res.data.storeInf.storeId;
					specList.value = goodsDetail.data.specs
					specProductList.value = goodsDetail.data.specProductList
					firstLoading.value = false

					if (goodsDetail.data.goodsFullDetails) {
						goodsDetail.data.goodsFullDetails = proxy.$quillEscapeToHtml(goodsDetail.data.goodsFullDetails);
						//处理背景样式
						if (goodsDetail.data.goodsFullDetails.indexOf('<p style="display:none;" data-background="') != -1) {
							let bg = goodsDetail.data.goodsFullDetails.split('<p style="display:none;" data-background="')[1].split('">')[0]
							goodsDetail.data.goodsFullDetailsBg = bg;
						}
					}
					productId.value = res.data.defaultProduct.productId
					document.title = goodsDetail.data.goodsName
				} else {
					ElMessage.error(res.msg);
				}
			}).then(res => {
				getStorePopular();
				getEvaluation();
				// getRecommend();

			})
		};


		const handleTemplate = () => {
			if (goodsDetail.data.topTemplateContent) {
				goodsDetail.data.topTemplateContent = proxy.$quillEscapeToHtml(goodsDetail.data.topTemplateContent);
				//处理背景样式
				if (goodsDetail.data.topTemplateContent.indexOf('<p style="display:none;" data-background="') != -1) {
					let bg = goodsDetail.data.topTemplateContent.split('<p style="display:none;" data-background="')[1].split('">')[0]
					goodsDetail.data.topTemplateContentBg = bg;
				}
			}
			if (goodsDetail.data.goodsDetails) {
				goodsDetail.data.goodsDetails = proxy.$quillEscapeToHtml(goodsDetail.data.goodsDetails);
				//处理背景样式
				if (goodsDetail.data.goodsDetails.indexOf('<p style="display:none;" data-background="') != -1) {
					let bg = goodsDetail.data.goodsDetails.split('<p style="display:none;" data-background="')[1].split('">')[0]
					goodsDetail.data.goodsDetailsBg = bg;
				}
			}
			if (goodsDetail.data.bottomTemplateContent) {
				goodsDetail.data.bottomTemplateContent = proxy.$quillEscapeToHtml(goodsDetail.data.bottomTemplateContent);
				//处理背景样式
				if (goodsDetail.data.bottomTemplateContent.indexOf('<p style="display:none;" data-background="') != -1) {
					let bg = goodsDetail.data.bottomTemplateContent.split('<p style="display:none;" data-background="')[1].split('">')[0]
					goodsDetail.data.bottomTemplateContentBg = bg;
				}
			}
		}


		//获取店铺推荐的商品(销量数);获取热门收藏的商品(收藏数)  type:recommend销量数  collection:收藏数
		const getStorePopular = (type) => {
			if (type == "" || !type) {
				type = "recommend";
			} else {
				storePopularType.value = type;
			}
			let params = {
				storeId: vid.value,
				sort: storePopularType.value == "recommend" ? 1 : storePopularType.value == "collection" ? 6 : "",
				pageSize: 6,
				current: 1,
			};
			proxy.$get("v3/goods/front/goods/list", params).then((res) => {
				if (res.state == 200) {
					let result = res.data;
					storePopularList.data = result.list;
					storePopularList.data.map(item => item.goodsPrice = new Number(item.goodsPrice).toFixed(2))
				} else {
					ElMessage.error(res.msg);
				}
			});
		};



		const recomOffShop = reactive({ data: [] })
		const getRecom = () => {
			proxy.$get('v3/goods/front/goods/list', { storeId: goodsDetail.data.storeId }).then(res => {

				if (res.state == 200) {
					let top = Math.floor(Math.random() * (res.data.list.length - 8)) + 8
					let end = top - 8
					recomOffShop.data = res.data.list.filter(item => item.defaultProductId != goodsDetail.data.defaultProduct.productId).slice(end, top)
				}
			})
		}



		//跳转去商品详情页
		const goGoodsDetail = (productId) => {
			proxy.$goGoodsDetail(productId);
		};


		/**
		 * 选择规格值
		 * @param type:类型  值:choice,规格选择  default:默认
		 * @param specId:父级规格值
		 * @param specValueId:点击的当前的规格值
		 */
		const selectSpecVal = (targetProId) => {
			getInitDataStatic(targetProId)
		};



		const editNum = (type) => {
			let { productStock } = goodsDetail.data.defaultProduct
			let maxBuyNum = Math.min(productStock, 9999)
			switch (type) {
				case 'reduce': {
					if (currentSpecNum.value <= 1) {
						return
					} else {
						currentSpecNum.value--
					}
					break
				}
				case 'edit': {
					if ((!/^[1-9]\d*$/.test(currentSpecNum.value) && currentSpecNum.value)) {
						currentSpecNum.value = 1
					} else if (currentSpecNum.value > maxBuyNum) {
						currentSpecNum.value = maxBuyNum;
						ElMessage('超过购买限制');
					}
					break
				}
				case 'add': {

					currentSpecNum.value++
					if (currentSpecNum.value > maxBuyNum) {
						currentSpecNum.value = maxBuyNum;
						ElMessage('超过购买限制');
					}
					break;
				}
				case 'blur': {
					if (currentSpecNum.value <= 1) {
						currentSpecNum.value = 1
						return;
					}
					break;
				}
			}
		}


		//加入购物车(多规格)
		const addCart = () => {

			if (!store.state.loginFlag) {
				loginModalVisibleFlag.value = true
				return
			}



			let minBuyNum = goodsDetail.data.ladderPriceList[0].buyNum
			if (goodsDetail.data.specProductList.length) {

				let { chosenSpecList, isLastChosen } = proxy.$refs.spec.returnChosenSpec()



				if (!chosenSpecList.length) {
					ElMessage('请选择商品')
					return
				}
				let numMap = chosenSpecList.map(i => i.buyNum)
				let totalNum = numMap.reduce((total, prev) => total + prev)
				if (totalNum < minBuyNum) {
					ElMessage('数量应大于最小起批量')
					return
				}
				let checkList = chosenSpecList.map(i => {
					return {
						number: i.buyNum,
						productId: i.productId
					}
				})
				proxy.$post('v3/business/front/cart/batchAdd', checkList, 'json').then(res => {
					if (res.state == 200) {
						ElMessage.success(res.msg)
						proxy.$getLoginCartListData()
					} else {
						ElMessage(res.msg)
					}
				})
			} else {

				if (currentSpecNum.value < minBuyNum) {
					ElMessage('数量应大于最小起批量')
					return
				}
				proxy.$post('v3/business/front/cart/add', {
					productId: goodsDetail.data.defaultProduct.productId,
					number: currentSpecNum.value
				}).then(res => {
					if (res.state == 200) {
						ElMessage.success(res.msg)
						proxy.$getLoginCartListData()
					} else {
						ElMessage(res.msg)
					}
				})
			}


		};



		//立即购买
		const goBuy = () => {

			if (!store.state.loginFlag) {
				loginModalVisibleFlag.value = true
				return
			}



			let query = {
				orderType: 1,
				goodsId: goodsDetail.data.goodsId,
				ifcart: false,
			}

			let productSets, prodSetMap
			let minBuyNum = goodsDetail.data.ladderPriceList[0].buyNum
			if (goodsDetail.data.specProductList && goodsDetail.data.specProductList.length) {

				let { chosenSpecList, isLastChosen } = proxy.$refs.spec.returnChosenSpec()

				// if (!isLastChosen) {
				// 	let txt
				// 	if (goodsDetail.data.specs.length == 1) {
				// 		txt = '请选择规格'
				// 	} else {
				// 		txt = '请选择最后一个规格'
				// 	}

				// 	ElMessage(txt)
				// 	return
				// }

				productSets = chosenSpecList
				if (!productSets.length) {
					ElMessage('请选择商品')
					return
				}

				let numMap = productSets.map(i => i.buyNum)
				let totalNum = numMap.reduce((total, prev) => total + prev)

				if (totalNum < minBuyNum) {
					ElMessage('数量应大于最小起批量')
					return
				}

				prodSetMap = productSets.map(i => `${i.productId}-${i.buyNum}`).join(',')
			} else {
				if (currentSpecNum.value < minBuyNum) {
					ElMessage('数量应大于最小起批量')
					return
				}
				prodSetMap = `${goodsDetail.data.defaultProduct.productId}-${currentSpecNum.value}`
			}


			query.productSets = encodeURIComponent(prodSetMap)


			router.push({
				path: "/buy/confirm",
				query,
			});
			localStorage.setItem('addressId', curAddr.value)

		};



		//关闭登录弹框
		const closeLoingModal = () => {
			loginModalVisibleFlag.value = false;
		};


		//关注店铺及取消关注
		const focusStore = () => {
			if (store.state.loginFlag) {
				//已登录
				let params = {
					storeIds: goodsDetail.data.storeInf.storeId,
					isCollect: !goodsDetail.data.storeInf.isFollowStore,
				};
				proxy.$post("v3/member/front/followStore/edit", params).then((res) => {
					if (res.state == 200) {
						goodsDetail.data.storeInf.isFollowStore = !goodsDetail.data.storeInf.isFollowStore;
						proxy.$refs.headercat.storeData.info.isFollow = goodsDetail.data.storeInf.isFollowStore ? "true" : "false";
						if (goodsDetail.data.storeInf.isFollowStore) {
							proxy.$sldStatEvent({ behaviorType: 'fol', storeId: goodsDetail.data.storeInf.storeId });
						}
					}
				});
			} else {
				//未登录提示登录
				loginModalVisibleFlag.value = true;
			}
		};



		//点击查看全部查看全部的商品规格参数
		const openParameter = () => {
			parameterShow.value = !parameterShow.value;
		};



		//获取元素距离父元素的顶部及左边的距离
		const offset = (el) => {
			let top = el.offsetTop;
			let left = el.offsetLeft;
			if (el.offsetParent) {
				el = el.offsetParent;
				top += el.offsetTop;
				left += el.offsetLeft;
			}
			return {
				left: left,
				top: top,
			};
		};

		const kefu = () => {

			if (!store.state.loginFlag) {
				loginModalVisibleFlag.value = true
				return
			}



			let { ladderPriceList, cooperate } = goodsDetail.data

			if (!(cooperate == 4 || cooperate == 0)) {
				ElMessage('申请合作成功即可联系客服')
				return
			}

			let chatInfo = {
				storeId: goodsDetail.data.storeInf.storeId,
				vendorAvatar: goodsDetail.data.storeInf.storeLogo,
				storeName: goodsDetail.data.storeInf.storeName,
				source: '从商品详情页进入',
				sourceType: 'goods',
				showData: {
					productId: goodsDetail.data.defaultProduct.productId,
					goodsName: goodsDetail.data.goodsName,
					goodsImage: goodsDetail.data.defaultProduct.goodsPics[0],
					productPrice: ladderPriceList.length ? ladderPriceList[0].ladderPrice : '--',
					goodsId: goodsDetail.data.goodsId
				}
			}
			store.commit('saveChatBaseInfo', chatInfo)

			let newWin = router.resolve({
				path: '/service',
				query: {
					vid: goodsDetail.data.storeInf.storeId
				}
			})

			window.open(newWin.href, "_blank")

		};


		//切换商品详情,评价,服务,热销
		const goodsDescType = (type) => {
			let elementScrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop; //获取页面滚动高度
			if (elementScrollTop > containerTop.value + 33 + 48) {
				window.scrollTo({
					top: containerTop.value,
					behavior: 'smooth'
				})
			}

			if (type == "" || !type) {
				goodsDesctionType.value = "detail";
			} else {
				goodsDesctionType.value = type;
			}
			if (type == "salestore") {
				getSalestore();
			}
		};

		//获取商品评价
		const getEvaluation = (type) => {
			if (!type) {
				evaluationType.value = "";
			} else {
				evaluationType.value = type;
			}
			let params = {
				productId: productId.value,
				current: evaluationCurrent.value,
				pageSize: evaluationPageSize.value,
				type: evaluationType.value,
			};
			proxy.$get("v3/goods/front/goods/comment", params).then((res) => {
				if (res.state == 200) {
					let result = res.data;
					goodsCommentsInfo.data = result;
					score.value = Number(goodsCommentsInfo.data.avgScore)
					goodsCommentsInfo.data && goodsCommentsInfo.data.list && goodsCommentsInfo.data.list.length > 0 && goodsCommentsInfo.data.list.map((commentsItem) => {
						commentsItem.memberName = commentsItem.memberName.slice(0, 1) + '***' + commentsItem.memberName.slice(commentsItem.memberName.length - 1, commentsItem.memberName.length);
					})
				} else {
					ElMessage.error(res.msg);
				}
			});
		};
		//评价列表上一页
		const handlePrevCilickChange = () => {
			if (evaluationCurrent.value == 1) {
				evaluationCurrent.value = 1;
			} else {
				evaluationCurrent.value--;
			}
			getEvaluation(evaluationType.value);
		};
		//评价列表下一页
		const handleNextCilickChange = () => {
			if (evaluationCurrent.value >= goodsCommentsInfo.data.list.length) {
				evaluationCurrent.value = goodsCommentsInfo.data.list.length;
			} else {
				evaluationCurrent.value++;
			}
			getEvaluation(evaluationType.value);
		};
		//评价列表当前选择页
		const handleCurrentChange = (val) => {
			evaluationCurrent.value = val;
			getEvaluation(evaluationType.value);
		};
		//获取热销店铺推荐
		const getSalestore = () => {
			let params = {
				storeId: goodsDetail.data.storeInf.storeId,
				sort: 7,
				pageSize: salesPageSize.value,
				current: salesCurrent.value,
			};
			proxy.$get("v3/goods/front/goods/list", params).then((res) => {
				if (res.state == 200) {
					let result = res.data;
					recommendeData.data = result;
					recommendedList.data = result.list;
					recommendedList.data.map(item => item.goodsPrice = new Number(item.goodsPrice).toFixed(2))
				} else {
					ElMessage.error(res.msg);
				}
			});
		};
		//店铺热销列表上一页
		const handlePrevCilickChangeSales = () => {
			if (evaluationCurrent.value == 1) {
				salesCurrent.value = 1;
			} else {
				salesCurrent.value--;
			}
			getSalestore();
		};
		//店铺热销列表下一页
		const handleNextCilickChangeSales = () => {
			if (salesCurrent.value >= goodsCommentsInfo.data.list.length) {
				salesCurrent.value = goodsCommentsInfo.data.list.length;
			} else {
				salesCurrent.value++;
			}
			getSalestore();
		};

		//店铺热销列表当前选择页
		const handleCurrentChangeSales = (val) => {
			salesCurrent.value = val;
			getSalestore();
		};

		const applyUpdate = () => {
			goodsDetail.data.cooperate = 3
		}

		const applyCoorpFuc = () => {

			if (!store.state.loginFlag) {
				loginModalVisibleFlag.value = true
				return
			}



			let { saleCooperateApplyUrl } = goodsDetail.data
			let { storeName, storeId } = goodsDetail.data.storeInf
			proxy.$refs.applyCoorp.setStoreInfo(storeName, storeId, saleCooperateApplyUrl)
			proxy.$refs.applyCoorp.dialogVisible = true
		}






		//进入店铺
		const goStore = () => {
			let tarUrl = router.resolve({
				path: "/store/index",
				query: {
					vid: vid.value,
				},
			});
			window.open(tarUrl.href, "_blank");
		};

		const refreshInfo = () => {
			history.go(0)
		};

		const updateFllow = (e) => {
			goodsDetail.data.storeInf.isFollowStore = e.state == 'true' ? true : false;
		};

		//暴露的变量及方法
		const currentAddr = (e) => {
			curAddr.value = e;

		}
		const expressFee = (res) => {
			goodsDetail.data.deliverInfo.expressFee = res.toFixed(2)
		}


		const goConsignment = () => {
			if (!store.state.loginFlag) {
				loginModalVisibleFlag.value = true
				return
			}

			nextTick(() => {
				if (goodsDetail.data.specProductList && goodsDetail.data.specProductList.length) {
					chosenSpecList.value = proxy.$refs.spec.returnChosenSpec().chosenSpecList
					if (!chosenSpecList.value.length) {
						ElMessage('请选择商品')
						return
					}
				}
				proxy.$refs.consignment.dialogVisible = true
			})
		}

		const consignUpdate = () => {
			getInitDataStatic(route.query.productId);
		}

		return {
			chosenSpecList,
			goConsignment,
			L,
			productId,
			goodsDetail,
			selectSpecVal,
			currentSpecNum,
			addCart,
			goBuy,
			focusStore,
			goGoodsDetail,
			vid,
			cartListData,
			loginModalVisibleFlag,
			closeLoingModal,
			firstLoading,
			container,
			containerTop,
			containCon,
			kefu,
			goStore,
			openParameter,
			parameterShow,
			score,
			colors,
			recomOffShop,
			address_list,
			curAddr,
			logFlag,
			refreshInfo,
			updateFllow,
			defaultProduct,
			expressFee,
			currentAddr,
			specList,
			specProductList,
			editNum,
			totalPrice,
			storePopularList,
			evaluationType,
			goodsDesctionType,
			goodsDescType,
			goodsCommentsInfo,
			evaluationType,
			getEvaluation,
			recommendedList,
			evaluationCurrent,
			evaluationPageSize,
			handlePrevCilickChange,
			handleNextCilickChange,
			handleCurrentChange,
			salesPageSize,
			salesCurrent,
			handlePrevCilickChangeSales,
			handleNextCilickChangeSales,
			handleCurrentChangeSales,
			storePopularType,
			getStorePopular,
			applyCoorpFuc,
			attrShow,
			applyUpdate,
			consignUpdate
		};
	},
};
</script>

<style lang="scss">
@import "../../style/base.scss";
@import "../../style/iconfont.css";
@import "../../style/goodsDetail.scss";

.popular_list_empty {
	height: 95px;

	font-size: 14px;
	font-family: Microsoft YaHei;
	font-weight: 400;
	color: #666666;
}

.container {
	margin-top: 30px;
}

.imageBorder {
	border: 1px solid #eee;
}

.goods_picture_big {
	border: 1px solid #eee;
}

.price_list {
	width: 690px;
}


.sld_goods_detail .goods_htmls .ql-video {
	width: 525px;
	height: 315px;
}

.sld_goods_detail .goods_htmls a {
	display: inline-block;
	margin: 5px auto;
	color: #0000FF;
	text-decoration: underline;
}

.sld_goods_detail .goods_htmls table {
	border-collapse: collapse;
	padding: 0;
}

.sld_goods_detail .goods_htmls td,
.sld_goods_detail .goods_htmls th {
	border: 1px solid #DDD;
	padding: 5px 10px;
}

.sld_goods_detail .goods_htmls ol li,
.sld_goods_detail .goods_htmls ul li {
	list-style: unset;
}

.sld_goods_detail {
	.summary {
		width: 830px;
		background: url(../../assets/goods/goods_detail_bg.png) 100% 100%;
		padding: 20px 20px 20px;
		box-sizing: border-box;
	}
}
</style>